import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const FullScreenImageModal = ({ isOpen, onRequestClose, imageUrl }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      border: "none",
      background: "none",
      width: "100%",
      height: "100%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000, // Ensure the modal is on top of everything
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Full Screen Image"
      
    >
      <div className="modal-content" onClick={onRequestClose}>
        <img src={imageUrl} alt="Full Screen" className="modal-image"  onClick={(e) => e.stopPropagation()}  />
      </div>
    </Modal>
  );
};

export default FullScreenImageModal;
