
import { useDispatch } from 'react-redux';

import {logout} from '../../../redux-toolkit/reducers/Auth/Auth'
import { useSelector } from 'react-redux';
export default function HeaderLogic() {
  
    const dispatch = useDispatch();
    const statusAuth = useSelector(state=>state.SIGN);

    // log out func
    const  handlelogout = ()=>{
      // localStorage.clear();
      //  sessionStorage.clear();
       dispatch(logout());
      }


    return {
        handlelogout,
        statusAuth
  }
}
