import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'

export const getPosts = createAsyncThunk(
    'home/getAllPosts', 
    async(_,thunkApi)=>{
    try{
         const res = await fetch (`${process.env.REACT_APP_API_URL}/get_home_site_info`);
         const data = await res.json();
         if(res.ok){
             return data;
         }
         else {
            
             return thunkApi.rejectWithValue(data);
         }
    }
    catch(error){
       
        return thunkApi.rejectWithValue(error.message)
    }
})



const initialState={
    post:[],
    loading:false,
    error:null,
}

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers:{
    [getPosts.pending]: (state,action)=>{
        state.loading =true;
        state.error=null;
    },
    [getPosts.fulfilled]: (state,action)=>{
        state.loading = false;
        state.post = action.payload;
    },
    [getPosts.rejected]:  (state,action)=>{
        state.loading=false;
        state.error=action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default HomeSlice.reducer