import React from 'react'
import ReactPaginate from 'react-paginate'
import './Pagination.css'


export default function Pagination({data,page,handlePageClick}) 
{

  return (
    <>
<ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        pageCount={data?.last_page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={"pagination "}
        activeClassName={"activeitem"}
      />




          
    </>
  )
}
