import { FieldTimeOutlined } from '@ant-design/icons';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import navigation from '../../assets/header/notification.png';
import MarkAsReadBtn from '../MarkAsReadBtn/MarkAsReadBtn';
import './Notify.css';

const Notify = ({text,image,status,notificatoin_id,isRead,linkto,timeStamp,refetch , student_id}) => {
    
    const role_id = localStorage.getItem('role');

    const handleSetNotificationAsReaded = (notificationId) => {


        axios.post(`${process.env.REACT_APP_API_URL}/mark_a_notification_as_readed/${notificationId}`
        ,{},
        {headers: {
            "Authorization": `Bearer ${ role_id == 3 ? localStorage.getItem('guardian_token') :  localStorage.getItem('token')}`
        }})
        .then((data) => {
            // console.log(data)
        })
        .catch((error) => {
            // console.log(error)
        })
    }



    return (
        <Link
        to={linkto} 
        className="disable-link-effects" 
        onClick={() => handleSetNotificationAsReaded(notificatoin_id)}
        state={{student_id:  role_id == 3 ?  student_id : null}}
        >

        <div 
        className="notify"
        style={{background: isRead == 1 ? 'rgba(255, 255, 255, 0.863)' : '#235C7033'}}
     
        >
        
        <div className='image_read_container'>
        <img loading="lazy"  
        src={image} 
        alt="" 
        className='notify_image'
        />

        <MarkAsReadBtn 
        isRead={isRead} 
        notificatoin_id={notificatoin_id}
        refetch={refetch}
        />

        </div>

        <div className='notify_content'>
        <p className='text-end'> {text.length < 50 ? text : text.slice(0,55) + '...'} </p>
        <div className='notify_time'>
        <FieldTimeOutlined />
        {timeStamp}
        </div>
        </div>
    
    </div>
    </Link>
    )
}

export default Notify;