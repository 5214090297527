import React, { useState } from "react";
import { IoImageOutline } from "react-icons/io5";
import { IoMicOutline } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { IoSend } from "react-icons/io5";
import pdfImg from "../../../assets/icons/pdf.png";
import micicon from "../../../assets/icons/mic.gif";

import ImageWithLoader from "./ImageWithLoader";

function MessageInput({
  sendMessage,
  setRecord,
  record,
  handleUploadFile,
  replyToMessage,
  setReplyToMessage,
  chatUser,
  handleReplyClick,
}) {
  const [text, setText] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handelSendClick();
    }
  };

  const handelSendClick = () => {
    if (text.trim() != "")
      sendMessage(text.trim().replace(/^[\n\s]+|[\n\s]+$/g, ""));
    setText("");
  };
  return (
    <>
      <div className={` position-relative  `} style={{ marginTop: "90px" }}>
        <div className="col-sm-11    d-flex justify-content-center align-items-center mx-auto bg-white ">
          {replyToMessage && (
            <div className={`rep col-sm-9 bg-white  `}>
              {
                <div className="row d-flex justify-content-between align-items-center   ">
                  <div className="col-1 ">
                    <span
                      className="close curser mx-3"
                      onClick={() => setReplyToMessage(null)}
                    >
                      x
                    </span>
                  </div>
                  <div
                    onClick={async () => {
                      handleReplyClick(replyToMessage.id);
                    }}
                    className={`col-11  p-1 gap ${
                      replyToMessage.type == "text"
                        ? "div_replay_text  "
                        : "div_replay_mult"
                    }`}
                  >
                    <div className="flex-col">
                      <p className="text">
                        {replyToMessage.is_sent_by_auth_user
                          ? "أنا"
                          : chatUser?.name}
                      </p>
                      {replyToMessage.type == "text" && (
                        <p className="text">{replyToMessage.content}</p>
                      )}
                    </div>
                    {(replyToMessage.type == "image" ||
                      replyToMessage.type == "file" ||
                      replyToMessage.type == "audio") && (
                      <ImageWithLoader
                        size={50}
                        src={
                          replyToMessage.type === "file"
                            ? pdfImg
                            : replyToMessage.type === "audio"
                            ? micicon
                            : replyToMessage.file_url ??
                              process.env.REACT_APP_STORAGE_URL +
                                replyToMessage.file
                        }
                        alt=""
                        className="img_replay mx-2"
                      />
                    )}
                  </div>
                </div>
              }
            </div>
          )}

          <div className="col-sm-9  bg-white  rounded-pill   d-flex justify-content-between align-items-center   mes  ">
            <div className="col-sm-1   d-flex justify-content-center align-items-center curser p-2">
              <IoSend onClick={handelSendClick} size={20} color="blue" />
            </div>

            <textarea
              type="text"
              className="textare"
              onKeyDown={handleKeyDown}
              value={text}
              onChange={(event) => {
                setText(event.target.value);
              }}
              required
            />

            <div className="col-lg-1 col-sm-2 d-flex justify-content-between align-items-center mx-3  ">
              <div onClick={() => document.getElementById("file").click()}>
                <RiAttachment2 size={25} color="blue" className="curser" />
              </div>

              <input
                id="file"
                type="file"
                className="d-none "
                onChange={(e) => handleUploadFile(e, "file")}
                accept="application/pdf"
              />
              <IoMicOutline
                size={25}
                color="red"
                className="curser"
                onClick={() => setRecord(!record)}
              />
              <div onClick={() => document.getElementById("image").click()}>
                <IoImageOutline size={25} color="blue" className="curser" />
              </div>

              <input
                id="image"
                type="file"
                className="d-none "
                onChange={(e) => handleUploadFile(e, "image")}
                accept="image/jpeg, image/png, image/gif"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageInput;
