import React from 'react';
import './index.css';
import chalkImg from '../../assets/icons/chalks2x.png';
import calendar from '../../assets/icons/calendar.png';
import { Link, useParams } from 'react-router-dom';

function ScreenTitle({
	title,
	secondTitle = false,
	secondTitleNum,
	thirdTitle,
	thirdTitleNum,
	completTitle = false,
	startDate = false,
	endDate = false,
	lessonNum,
	withOutAdTitles = false,
	subjectID = null
}) {

	const params = useParams();
	// console.log('params from title screen', window.location.pathname);
	return (
		<div
			className={`pagetitle ${completTitle && startDate && 'expanded'} ${
				withOutAdTitles && 'withoutAdditionsTitles'
			}`}>
			<div className='wraptitle'>
				<h2 className='pagetitle__title'>
					<img loading="lazy"  src={chalkImg} alt='title' />


					{subjectID ? <Link to={`/subjectt/${subjectID}`}>{title}</Link> : title }
					{/* {title} */}

					{completTitle && `${' - ' + completTitle}`}
					{lessonNum && `${' - ' + lessonNum}`}
				</h2>
				{!withOutAdTitles && (
					<div className='pagetitle__infos'>
						<div className='infos__units'>
							{}
							{secondTitle && 
							<>
							{secondTitle}
							<div className='infos__circle'></div>
							{secondTitleNum}
							<div className='infos__sector'></div>
							</>
							}
						</div>
						
						<div className='infos__units'>
							{thirdTitle}
							{thirdTitle && <div className='infos__circle' dir='ltr'></div> }
							{thirdTitleNum}
						</div>
					</div>
				)}
			</div>

			{startDate && (
				<div className='date'>
					<h5 className='title'>
						<img loading="lazy"  src={calendar} alt='' /> مدة الانجاز
					</h5>
					{'من ' + startDate + ' الى ' + endDate}
				</div>
			)}
		</div>
	);
}

export default ScreenTitle;
