import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer/Footer'
import Header from './Header/Header'

import './PrimaryLayout.css'
const PrimaryLayout = (props) => {
  return (
    <div className='layout'>
        <Header />
           <div className='children'>
             <Outlet/>
             {props.children}
             </div>
        <Footer/>
    </div>
  )
}

export default PrimaryLayout