import { lazy } from 'react';
import Loadable from '../../components/Loadable/Loadable';
import PrimaryLayout from '../../Layouts/PrimaryLayout/PrimaryLayout';
import AutomatedUnit from '../pages/automatedUnit/automatedUnit';

// import pages as lazy loading with loadable commpanent

const SubjectScreen =  Loadable( lazy(() => import('../pages/Subject/Subject'))) ;
const MainQizes =  Loadable( lazy(() => import('../pages/MainQizes/MainQizes'))) ;
const MainQuizesTraditional =  Loadable( lazy(() => import('../pages/MainQuizesTraditional/MainQuizesTraditional'))) ;
const ExamTraditional =  Loadable( lazy(() => import('../pages/examTraditional/examTraditional'))) ;
const ProfileStudent =  Loadable( lazy(() => import('../pages/ProfileStudent/ProofileStudent'))) ;
const ProfileScreenByToken =  Loadable( lazy(() => import('../pages/profileScreenByToken/index'))) ;
const MyPoints =  Loadable( lazy(() => import('../pages/MyPoints/MyPoints'))) ;
const SubjectsPage =  Loadable( lazy(() => import('../pages/SubjectsPage/index'))) ;
const SubjectsScreen =  Loadable( lazy(() => import('../pages/subjectsScreen/index'))) ;
const LessonScreen =  Loadable( lazy(() => import('../pages/lessonScreen/index'))) ;
const OptimizedLessonScreen =  Loadable( lazy(() => import('../pages/lessonScreen/OptimizedIndex'))) ;
const StudentSubject =  Loadable( lazy(() => import('../pages/StudentSubject/index'))) ;
const StudentArchiveScreen =  Loadable( lazy(() => import('../pages/studentArchive/index'))) ;
const Professors =  Loadable( lazy(() => import('../pages/Professors/Professors'))) ;
const Professorsabbreviation =  Loadable( lazy(() => import('../pages/Professorsabbreviation/Professorsabbreviation'))) ;
const ExamsTableScreen =  Loadable( lazy(() => import('../pages/examTableScreen/index'))) ;
const Quizto =  Loadable( lazy(() => import('../../components/Quiz_To/Quiz_To'))) ;
const LessonQuizes =  Loadable( lazy(() => import('../../components/Students/LessonQuiz/LessonQuizes/LessonQuizes'))) ;
const Show_LessonAnswear =  Loadable( lazy(() => import('../../components/Students/LessonQuiz/ShowLessonAnswer/ShowLessonAnswer'))) ;
const UnitQuizCoreected =  Loadable( lazy(() => import('../../components/Students/UnitQuiz/UnitQuizCorrected'))) ;
const Show_type_test =  Loadable( lazy(() => import('../../components/Show_type_test/Show_Type_test'))) ;
const Testinlesson =  Loadable( lazy(() => import('../../components/Testinlesson/Testinlesson'))) ;
const ShowAsnwearUnitTranditiosnal =  Loadable( lazy(() => import('../../components/teacher/Unit_Quiz/Show_Asnwear_Unit_Tranditiosnal/Show_Asnwear_Unit_Tranditiosnal'))) ;
const StudentExams =  Loadable( lazy(() => import('../pages/StudentExams/index'))) ;
const MainExamDetails =  Loadable( lazy(() => import('../pages/MainExamDetails/index'))) ;
const AllNotification =  Loadable( lazy(() => import('../pages/AllNotification/index'))) ;
const Registeration =  Loadable( lazy(() => import('../pages/Registeration/Registeration'))) ;
const UnitScreen =  Loadable( lazy(() => import('../pages/UnitScreen'))) ;
const OrdersScreen =  Loadable( lazy(() => import('../pages/OrdersScreen/index'))) ;
const LoginAsStudent =  Loadable( lazy(() => import('../pages/LoginAsStudent/index'))) ;


const StudentRoutes = [
    {
        path:'/',
        element: <PrimaryLayout/>,
        children: [
            {
                path: 'automatedUnit/:id',
                element: <AutomatedUnit />
            },
            {
                path: 'loginasstudent/:studentToken/:isactive',
                element: <LoginAsStudent />
            },
            {
                path: 'ordersRecord',
                element: <OrdersScreen />
            },
            {
                path: 'unitt/:id',
                element: <UnitScreen />
            },
            {
                path: 'registeration',
                element: <Registeration />
            },
            {
                path: 'subjectt/:id',
                element: <SubjectScreen />
            },
            {
                path: 'mainquizes',
                element: <MainQizes />
            },
            {
                path: 'mainquizesTraditional/:id',
                element: <MainQuizesTraditional />
            },
            {
                path: 'examTradtional/:id',
                element: <ExamTraditional />
            },
            {
                path: 'profileStudent',
                element: <ProfileStudent />
            },
            {
                path: 'studentprofile',
                element: <ProfileScreenByToken />
            },
            {
                path: 'points',
                element: <MyPoints />
            },
            {
                path: 'studentSubjects',
                element: <SubjectsPage />
            },
            {
                path: 'subjectss/:id',
                element: <SubjectsScreen />
            },
            {
                path: 'lesson/:lessonId',
                element: <OptimizedLessonScreen />
            },
            // {
            //     path: 'lesson-optimized/:lessonId',
            //     element: <OptimizedLessonScreen />
            // },
            {
                path: 'studentsubject',
                element: <StudentSubject />
            },
            {
                path: 'studentarchive',
                element: <StudentArchiveScreen />
            },
            {
                path: 'teachers',
                element: <Professors />
            },
            {
                path: 'teacher/:id',
                element: <Professorsabbreviation />
            },
            {
                path: 'examtable',
                element: <ExamsTableScreen />
            },
            {
                path: 'myquizes',
                children: [
                    {
                        index: true,
                        element: <Quizto />
                    },
                    {
                        path: 'lessons',
                        children: [
                            {
                                index: true,
                                element: <LessonQuizes api='/get_student_lesson_automated_quizzes'/>
                            },
                            {
                                path: 'automatedquiz/:QuizId',
                                element: <Show_LessonAnswear /> 
                            }
                        ]
                    },
                    {
                        path: 'units',
                        children: [
                            {
                                index: true,
                                element: <Show_type_test/>
                            },
                            {
                                path: 'automatedquiz' , 
                                element: <LessonQuizes api='/get_student_unit_automated_quizzes' />
                            },
                            {
                                path: 'automatedquiz/:QuizId', 
                                element: <Show_LessonAnswear/>
                            },
                            {
                                path: 'traditional_test_unit' , 
                                children: [
                                    {
                                        index: true,
                                        element: <Testinlesson />
                                    },
                                    {
                                        path: 'corrected',
                                        children: [
                                            {
                                                index: true , 
                                                element: <UnitQuizCoreected api='/get_student_corrected_unit_traditional_quizzes' />
                                            },
                                            {
                                                path: 'show/:QuizId', 
                                                element: <ShowAsnwearUnitTranditiosnal />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'uncorrected', 
                                        children: [
                                            {
                                                index: true ,
                                                element: <UnitQuizCoreected api='/get_student_uncorrected_unit_traditional_quizzes' />
                                            },                                           
                                            {
                                                path: 'show/:QuizId', 
                                                element: <ShowAsnwearUnitTranditiosnal />
                                            }
                                        ]

                                    }
                                ]
                            },
                           
                        ]
                    }
                ]
            },
            {
                path: 'myexams',
                element: <StudentExams />
            },
            {
                path: 'mainExamDetails/:QuizId',
                element: <MainExamDetails />
            },
            {
                path: 'notification', 
                element: <AllNotification />
            }
        ]
    },


];

export default StudentRoutes;
