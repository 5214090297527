import React, { useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";
import Message from "./Message";

const MessagesLoader = () => {
  const messages = [
    {
      direction: "rtl",
    },
    {
      direction: "ltr",
    },
    {
      direction: "rtl",
    },
    {
      direction: "ltr",
    },
    {
      direction: "rtl",
    },
    {
      direction: "ltr",
    },
  ];

  return (
    <>
      {messages.map((msg) => {
        return (
          <div
            style={{
              direction: msg.direction,
            }}
          >
            <ShimmerThumbnail height={35} width={175} rounded />
          </div>
        );
      })}
    </>
  );
};

export default MessagesLoader;
