import { useEffect } from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import AllRoutes from './routes';
import axios from 'axios';



function App() {

  const queryClient = new QueryClient();

  	// check if the token is valid or not 
      useEffect(() => {

        // if the use was student
        if(localStorage.getItem('role') == 1){
          axios.get(`${process.env.REACT_APP_API_URL}/get_student_by_token`,
          {headers: {"Authorization": `Bearer ${localStorage.getItem('token') || localStorage.getItem('guardian_token')}`}})
          .then((response) => {
        
          // console.log('response get student by token',response.data.data);
    
          // set the account situation to know how to handle user permessions inside the website
          localStorage.setItem('is_active',response.data.data.is_active);
          localStorage.setItem('has_subscribed_before', response.data.data.has_subscribed_before)
    
          if(response.data.data.is_active == 0 && !window.location.pathname.includes('notactive')){
            window.location.replace('/notactive');
          }
          
          })
          .then(data => {
          // console.log('data ', data)
          })
          .catch(error => {
          // handleLogout();
          if(error.response.data.code == 401){
            window.location.reload();
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('has_subscribed_before');
            if (localStorage.getItem('token2')) {
            localStorage.setItem('token', localStorage.getItem('token2'));
            localStorage.setItem('role', localStorage.getItem('role2'));
            localStorage.removeItem('token2');
            localStorage.removeItem('role2');
            }
          
            window.location.replace('/')
          }
          // console.log('error get admin by token ', error.response.data.code);
          })
          .finally(() => {
          // console.log('finally i exit this');
          // setIsLoading(false);
          })
        }
        
      },[])

  return (
    <QueryClientProvider client={queryClient}>

      <Router>
        <AllRoutes/>
      </Router>



      </QueryClientProvider>
  );
}

export default App;
