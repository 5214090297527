import {
	createSlice,
	createAsyncThunk,
	createDraftSafeSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';

export const getSubjectComment = createAsyncThunk(
	'subjectComment/getAllsubjectComment',
	async (id, thunkApi) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_all_comments_by_subject_id/${id}?limit=10&local=ar`
			);
			const data = await res.json();
			if (res.ok) {
				return data;
			} else {
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);
export const postSubjectComment = createAsyncThunk(
	'subjectComment/postSubjectComment',
	async ({ comment, subjectId }, thunkApi) => {

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/post_comment`,
				{
					comment,
					subject_id: subjectId,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			);

			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
	isError: '',
	isSuccess: '',
};

export const SubjectCommentSlice = createSlice({
	name: 'subjectComment',
	initialState,
	reducers: {
		clearMsg(state, action) {

			state.isError = '';
			state.isSuccess = '';
		},
	},
	extraReducers: {
		[getSubjectComment.pending]: (state, action) => {
			// state.loading = true;
			state.error = null;
		},
		[getSubjectComment.fulfilled]: (state, action) => {
			// state.loading = false;
			//   console.log("fulfilled", action.payload);
			//   console.log("subjectData", action.payload);
			state.post = action.payload.data.data;
		},
		[getSubjectComment.rejected]: (state, action) => {
			//   console.log("rejected_aboutUs", action);
			// state.loading = false;
			state.error = action.payload;
		},
		[postSubjectComment.pending]: (state, action) => {
			state.loading = true;
		},
		[postSubjectComment.fulfilled]: (state, action) => {
			state.loading = false;
			state.post.push(action.payload.data.data);
			state.isSuccess = 'تم التعليق بنجاح';
		},
		[postSubjectComment.rejected]: (state, action) => {
			state.loading = false;
			state.isError =
				action.payload?.response?.data?.message || 'حدث خطأ بالشبكة';
		},
	},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const SubjectCommentsSelector = createDraftSafeSelector(
	(state) => state.subjectComment,
	(subjectComment) => subjectComment.post
);
export const getSuccess = createDraftSafeSelector(
	(state) => state.subjectComment,
	(opinion) => opinion.isSuccess
);

export const getError = createDraftSafeSelector(
	(state) => state.subjectComment,
	(opinion) => opinion.isError
);
export const getLoading = createDraftSafeSelector(
	(state) => state.subjectComment,
	(subject) => subject.loading
);
export const { clearMsg } = SubjectCommentSlice.actions;
export default SubjectCommentSlice.reducer;
