import {lazy} from 'react';
import Loadable from '../../components/Loadable/Loadable';
import ShowallautomatedfinalQuiz from '../../components/Show_all_automated_final_Quiz/ShowallautomatedfinalQuiz';
import PrimaryLayout from '../../Layouts/PrimaryLayout/PrimaryLayout';


const SelectlUnitForLesson = Loadable(lazy(() => import('../../components/teacher/Units/Selectl_Unit_For_Lessons/Selectl_Unit_For_Lessons')));
const Showalllessonforquiz = Loadable(lazy(() => import('../../components/teacher/Lessons/Show_All_Lesson_For_Quiz/Show_All_Lesson_For_Quiz')));
const Createautomatedquizlesson = Loadable(lazy(() => import('../../components/teacher/Lesson_Quiz/Create_Automated_Quiz_Lesson/Create_Automated_Quiz_Lesson')));
const Showautomatedquizoptions = Loadable(lazy(() => import('../../components/Show_Automated_Quiz_Options/Show_Automated_Quiz_Options')));
const Addautomatedquizquistionsforlesson = Loadable(lazy(() => import('../../components/teacher/Lesson_Quiz/Add_Automated_Quiz_Quistions_For_Lesson/Add_Automated_Quiz_Quistions_For_Lesson')));
const EditautomatedquizLesson = Loadable(lazy(() => import('../../components/EditautomatedquizLesson/EditautomatedquizLesson')));
const Selectunit = Loadable(lazy(() => import('../../components/Select_Unit/Select_Unit')));
const QuizCard = Loadable(lazy(() => import('../../components/QuizCard/QuizCard')));
const ShowallautomatedfinquizesalQuiz = Loadable(lazy(() => import('../../components/Show_all_automated_final_Quiz/ShowallautomatedfinalQuiz')));
const Createunitautomatedquiz = Loadable(lazy(() => import('../../components/Create_unit_automated_quiz/Create_unit_automated_quiz')));
const Addautomatedquizquistions = Loadable(lazy(() => import('../../components/Add_automated_quiz_quistions/Add_automated_quiz_quistions')));
const Editautomatedquizunit = Loadable(lazy(() => import('../../components/Edit_Automated_Quiz_Unit/Edit_Automated_Quiz_Unit')));
const ShowalluplevelQuiz = Loadable(lazy(() => import('../../components/Show_all_up_level_Quiz/Show_all_up_level_Quiz')));
const Addtraditionalquiz = Loadable(lazy(() => import('../../components/Add_traditional_quiz/Add_traditional_quiz')));
const Edittraditionalquiz = Loadable(lazy(() => import('../../components/edit_traditional_quiz/Edit_traditional_quiz')));
const Addtraditionaquizquistionsfiles = Loadable(lazy(() => import('../../components/Add_Traditiona_Quiz_Quistions_Files/Add_Traditiona_Quiz_Quistions_Files')));
const Showtraditionalquizquestionsfiles = Loadable(lazy(() => import('../../components/Show_Traditionalquiz_Questions_Files/Show_Traditionalquiz_Questions_Files')));
const ShowallautomateduplevelQuiz = Loadable(lazy(() => import('../../components/Show_all_automated_uplevel_Quiz/ShowallautomateduplevelQuiz')));

const ShowallSubjects = Loadable(lazy(() => import('../../components/teacher/Subjects/Show_All_Subjects/Show_All_Subjects')));
const Showallunits = Loadable(lazy(() => import('../../components/teacher/Units/Show_All_Unit/Show_All_Unit')));
const UnitComment = Loadable(lazy(() => import('../../components/teacher/Units/comments/Show_All_Comments/Show_All_Comments')));
const EditUnitComment = Loadable(lazy(() => import('../../components/teacher/Units/comments/Edit_Comment/Edit_Comment')));
const Editunit = Loadable(lazy(() => import('../../components/teacher/Units/Edit_Unit/Edit_Unit')));
const Showalllesson = Loadable(lazy(() => import('../../components/teacher/Lessons/Show_All_Lesson/Show_All_Lesson')));
const CommentsLesson = Loadable(lazy(() => import('../../components/teacher/Lessons/comments/Show_All_Comments/Show_All_Comments')));
const EditLessonComment = Loadable(lazy(() => import('../../components/teacher/Lessons/comments/Edit_Comment/Edit_Comment')));
const AddLessonToUnit = Loadable(lazy(() => import('../../components/teacher/Lessons/Add_Lesson_To_Unit/Add_Lesson_To_Unit')));
const EditLesson = Loadable(lazy(() => import('../../components/teacher/Lessons/Edit_Lesson/Edit_Lesson')));
const ShowLessonFaqs = Loadable(lazy(() => import('../../components/teacher/Lessons/faqs/Show_All_Lesson_Faqs/Show_All_Lesson_Faqs')));
const Addlessonfaqs = Loadable(lazy(() => import('../../components/teacher/Lessons/faqs/Add_Lesson_Faqs/Add_Lesson_Faqs')));
const Editlessonfaqs = Loadable(lazy(() => import('../../components/teacher/Lessons/faqs/Edit_Lesson_Faqs/Edit_Lesson_Faqs')));
const ShowUnitFaqs = Loadable(lazy(() => import('../../components/teacher/Units/faqs/Show_Unit_Faqs/Show_Unit_Faqs')));
const Addunitfaqs = Loadable(lazy(() => import('../../components/teacher/Units/faqs/Add_Unit_Faqs/Add_Unit_Faqs')));
const Editunitfaqs = Loadable(lazy(() => import('../../components/teacher/Units/faqs/Edit_Unit_Faqs/Edit_Unit_Faqs')));
const Addunit = Loadable(lazy(() => import('../../components/Add_Unit/Add_Unit')));
const Quiz_To = Loadable(lazy(() => import('../../components/Quiz_To/Quiz_To')));

// import pages as lazy loading with loadable commpanent

const Showallmaterialforquiz = Loadable(lazy(() => import('../pages/Show_All_Material_For_Quiz/Show_All_Material_For_Quiz')));
const Options = Loadable(lazy(() => import('../pages/options/Options')));
const ProfileDetailsTeacher = Loadable(lazy(() => import('../pages/ProfileDetailsTeacher/ProfileDetailsTeacher')));
const Createtest = Loadable(lazy(() => import('../pages/create_test/Create_test')));


const Students = Loadable(lazy(() => import('../../components/Students/Students')));
const Quizto = Loadable(lazy(() => import('../../components/Quiz_To/Quiz_To')));
const Lesson_Quizes = Loadable(lazy(() => import('../../components/teacher/Lesson_Quiz/Lesson_Quizes/Lesson_Quizes')));
const ShowLessonAnswear = Loadable(lazy(() => import('../../components/teacher/Lesson_Quiz/Show_Lesson_Answear/Show_Lesson_Answear')));
const Show_type_test = Loadable(lazy(() => import('../../components/Show_type_test/Show_Type_test')));
const Testinlesson = Loadable(lazy(() => import('../../components/Testinlesson/Testinlesson')));
const Unit_Quiz_Coreected = Loadable(lazy(() => import('../../components/teacher/Unit_Quiz/Unit_Quiz_Coreected/Unit_Quiz_Coreected')));
const ShowasnwearUnittranditiosnal = Loadable(lazy(() => import('../../components/teacher/Unit_Quiz/Show_Asnwear_Unit_Tranditiosnal/Show_Asnwear_Unit_Tranditiosnal')));
const StudentsQuizez = Loadable(lazy(() => import('../pages/StudentsQuizez/StudentsQuizez')));
const ProfileScreenById = Loadable(lazy(() => import('../pages/profileScreenById/index')));
const StudentsVisas = Loadable(lazy(() => import('../pages/StudentsVisas/StudentsVisas')));
const Student_Details = Loadable(lazy(() => import('../../components/teacher/visas/StudentDetails/Student_Details')));
const ProfileScreen = Loadable(lazy(() => import('../pages/profileScreenById/index')));
const LoginAsTeacher = Loadable(lazy(() => import('../pages/LoginAsTeacher/index')));
const TeacherAttatchment = Loadable(lazy(() => import('../pages/teacherAttatchment/index')));


const TeacherRoutes = [{
    path: '/', element: <PrimaryLayout/>, children: [{
        path: 'lessonAttatchment/:id', element: <TeacherAttatchment/>
    }, {
        path: 'loginasteacher/:teacherToken', element: <LoginAsTeacher/>
    }, {
        path: 'studentsQuizez/:subjectId', element: <StudentsQuizez/>
    }, {
        path: 'studentprofilebyid/:StudentId', element: <ProfileScreenById putLayout={true}/>
    }, {
        path: 'options', element: <Options/>, children: [{
            index: true, element: <ProfileDetailsTeacher/>
        }, {
            path: 'create_quiz', children: [{
                index: true, element: <Showallmaterialforquiz/>
            }, {
                path: ':SubjectId', children: [{
                    index: true, element: <Quiz_To/>
                }, {
                    path: 'lessons', children: [{
                        index: true, element: <SelectlUnitForLesson/>
                    }, {
                        path: ':UnitId/show_lessons',

                        children: [{
                            index: true, element: <Showalllessonforquiz/>,
                        }, {
                            path: 'create_quiz/:LessonId', element: <Createautomatedquizlesson/>
                        }, {
                            path: 'show_questions/:QuizId', element: <Showautomatedquizoptions/>
                        }, {
                            path: 'show_questions/lesson/:lessonId', element: <Showautomatedquizoptions/>
                        }, {
                            path: 'add_questions/:QuizId', element: <Addautomatedquizquistionsforlesson/>
                        }, {
                            path: 'add_questions/lesson/:lessonId', element: <Addautomatedquizquistionsforlesson/>
                        }, {
                            path: 'edit/:QuizId', element: <EditautomatedquizLesson/>
                        }]
                    }, {}]
                }, {
                    path: 'units', children: [{
                        index: true, element: <Selectunit/>
                    }, {
                        path: 'type_quiz/:UnitId', children: [{
                            index: true, element: <QuizCard/>
                        }, {
                            path: 'final', children: [{
                                index: true, element: <Createtest/>

                            }, {
                                path: 'automatedquiz', children: [{
                                    index: true, element: <ShowallautomatedfinalQuiz/>

                                }, {
                                    path: 'create_unit_automated_quiz', element: <Createunitautomatedquiz isFinal={1}
                                                                                                          isAboveLevel={0}/>
                                }, {
                                    path: 'add_automated_quiz_questions/:QuizId', element: <Addautomatedquizquistions/>
                                }, {
                                    path: 'edit/:QuizId', element: <Editautomatedquizunit
                                        isFinal={0}
                                        isAboveLevel={1}
                                    />
                                }, {
                                    path: 'show_automated_quiz_questions_options/:QuizId',
                                    element: <Showautomatedquizoptions/>
                                }]
                            },

                                {
                                    path: 'traditional_test_unit', children: [{
                                        index: true, element: <ShowalluplevelQuiz/>
                                    }, {
                                        path: 'add_quiz', element: <Addtraditionalquiz
                                            isFinal={0}
                                            isAboveLevel={1}
                                        />
                                    }, {
                                        path: 'edit/:QuizId', element: <Edittraditionalquiz
                                            isFinal={0}
                                            isAboveLevel={1}
                                        />
                                    }, {
                                        path: 'add_traditional_quiz_questions_files/:QuizId',
                                        element: <Addtraditionaquizquistionsfiles/>
                                    }, {
                                        path: 'show_traditional_quiz_questions_files/:QuizId',
                                        element: <Showtraditionalquizquestionsfiles/>
                                    }]
                                }

                            ]
                        }, {
                            path: 'up_level', children: [{
                                index: true, element: <Createtest/>
                            }, {
                                path: 'traditional_test_unit', children: [{
                                    index: true, element: <ShowalluplevelQuiz/>
                                }, {
                                    path: "add_quiz", element: <Addtraditionalquiz isFinal={0}
                                                                                   isAboveLevel={1}/>
                                }, {
                                    path: 'edit/:QuizId', element: <Edittraditionalquiz
                                        isFinal={0}
                                        isAboveLevel={1}
                                    />
                                }, {
                                    path: 'add_traditional_quiz_questions_files/:QuizId',
                                    element: <Addtraditionaquizquistionsfiles/>
                                }, {
                                    path: 'show_traditional_quiz_questions_files/:QuizId',
                                    element: <Showtraditionalquizquestionsfiles/>
                                }]
                            }, {
                                path: 'automatedquiz', children: [{
                                    index: true, element: <ShowallautomateduplevelQuiz/>
                                }, {
                                    path: 'create_unit_automated_quiz', element: <Createunitautomatedquiz
                                        isFinal={0}
                                        isAboveLevel={1}
                                    />
                                }, {
                                    path: 'show_automated_quiz_questions_options/:QuizId',
                                    element: <Showautomatedquizoptions/>
                                }, {
                                    path: 'add_automated_quiz_questions/:QuizId', element: <Addautomatedquizquistions/>
                                }, {
                                    path: 'edit/:QuizId', element: <Editautomatedquizunit
                                        isFinal={0}
                                        isAboveLevel={1}
                                    />
                                }]
                            }]
                        }]
                    },]
                }

                ]
            }]
        }, {
            path: 'mysubjects', children: [{
                index: true, element: <ShowallSubjects/>
            }, {
                path: 'showallunit/:SubjectId', children: [{
                    index: true, element: <Showallunits/>
                }, {
                    path: 'add_unit', element: <Addunit/>
                }, {
                    path: ':UnitId/comments', children: [{
                        index: true, element: <UnitComment/>
                    }, {
                        path: 'edit_comment/:CommentId', element: <EditUnitComment/>
                    }]
                }, {
                    path: ':UnitId/unit_faqs', children: [{
                        index: true, element: <ShowUnitFaqs/>
                    }, {
                        path: 'add_unit_faqs', element: <Addunitfaqs/>
                    }, {
                        path: 'edit_unit_faqs/:faqId', element: <Editunitfaqs/>
                    }]
                }, {
                    path: 'edit_unit/:UnitId', element: <Editunit/>
                }, {
                    path: ':UnitId/show_lessons', children: [{
                        index: true, element: <Showalllesson/>
                    }, {
                        path: 'comments/:LessonId', children: [{
                            index: true, element: <CommentsLesson/>
                        }, {
                            path: 'edit_comment/:CommentId', element: <EditLessonComment/>
                        }]
                    }, {
                        path: 'add_lesson', element: <AddLessonToUnit/>
                    }, {
                        path: 'edit_lesson/:LessonId', element: <EditLesson/>
                    }, {
                        path: ':LessonId/Lesson_faqs', children: [{
                            index: true, element: <ShowLessonFaqs/>
                        }, {
                            path: 'add_lesson_faqs', element: <Addlessonfaqs/>
                        }, {
                            path: 'edit_lesson_faqs/:faqId', element: <Editlessonfaqs/>
                        }]
                    },

                    ]
                }]
            }]
        }, {
            path: 'quizes', children: [{
                index: true, element: <Students
                    // Title={Title + '| الاختبارات '}
                    to='typequiz/*'
                    // api='/get_all_students_that_have_quizzes_by_teacher'
                    api='/get_teacher_subjects'
                />
            }, {
                path: 'typequiz/:StudentId', children: [{
                    index: true, element: <Quizto/>
                }, {
                    path: 'lessons', children: [{
                        index: true, element: <Lesson_Quizes
                            api='/get_lesson_automated_quizzes_by_student_id_and_subject_id?'/>
                    }, {
                        path: 'automatedquiz/:QuizId', element: <ShowLessonAnswear/>
                    }]
                }, {
                    path: 'units', children: [{
                        index: true, element: <Show_type_test/>
                    }, {
                        path: 'traditional_test_unit', children: [{
                            index: true, element: <Testinlesson/>
                        }, {
                            path: 'corrected', children: [{
                                index: true, element: <Unit_Quiz_Coreected
                                    api='/get_corrected_unit_traditional_quizzes_by_student_id_and_subject_id?'/>
                            }, {
                                path: 'show/:QuizId', element: <ShowasnwearUnittranditiosnal
                                    corrected={true}/>
                            }]
                        }, {
                            path: 'uncorrected', children: [{
                                index: true, element: <Unit_Quiz_Coreected
                                    api='/get_uncorrected_unit_traditional_quizzes_by_student_id_and_subject_id?'/>
                            }, {
                                path: 'show/:QuizId', element: <ShowasnwearUnittranditiosnal
                                    corrected={false}/>
                            }]
                        }]
                    }, {
                        path: 'automatedquiz', children: [{
                            index: true, element: <Lesson_Quizes
                                api='/get_unit_automated_quizzes_by_student_id_and_subject_id?'
                                isUnit={true}
                            />
                        }, {
                            path: 'automatedquiz/:QuizId', element: <ShowLessonAnswear/>
                        }]
                    }]
                }]
            }]
        }, {
            path: 'visas', children: [{
                index: true, element: <StudentsVisas
                    // Title={Title + '| التأشيرات'}
                    to='student'
                    api='/get_all_exit_visas_students_by_teacher'
                />
            }, {
                path: 'student/:StudentId', children: [{
                    index: true, element: <Student_Details/>
                }, {
                    path: 'studentprofile', element: <ProfileScreen/>
                }]
            }]
        }]
    },]
},


];

export default TeacherRoutes;
