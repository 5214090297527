import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// unit faqs

export const get_faqs_by_id = createAsyncThunk(
  "Units/get_faqs_by_id",
  async ({ faqId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_faqs_by_id/${faqId}`,
        {
          headers: { Authorization: `Bearer ${SIGN.token}` },
        }
      );
      const { data } = await res.json();
      if (res.ok) {
        return data;
      } else {
     
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {


      return thunkApi.rejectWithValue(
        error.message === "Failed to fetch"
          ? "لا يوجد وحدات لعرضها"
          : error.message
      );
    }
  }
);

export const add_unit_faqs = createAsyncThunk(
  "Units/add_unit_faqs",
  async ({ formdata }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/add_faqs`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${SIGN.token}`,
        },
        body: formdata,
      });
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const update_unit_faqs_by_id = createAsyncThunk(
  "Units/update_unit_faqs_by_id",
  async ({ formdata, faqId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/update_faqs/${faqId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
          body: formdata,
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const delete_unit_faqs_by_id = createAsyncThunk(
  "Units/delete_unit_faqs_by_id",
  async ({ faqId, Refetch }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delete_faqs/${faqId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        Refetch();
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// unit comment

//  get unit comment by id

export const get_unit_comment_by_id = createAsyncThunk(
  "Units/get_unit_comment_by_id",
  async ({ CommentId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_unit_comment_by_id/${CommentId}`,
        {
          headers: { Authorization: `Bearer ${SIGN.token}` },
        }
      );
      const { data } = await res.json();
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// delete unit comment

export const delete_comment_for_unit = createAsyncThunk(
  "Units/delete_comment_for_unit",
  async ({ CommentId, Refetch }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${CommentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        Refetch();
        alert('تمت علمية الحذف ')
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// update comment for unit

export const update_comment_for_unit = createAsyncThunk(
  "Units/update_comment_for_unit",
  async ({ formdata, CommentId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/update_comment_for_unit/${CommentId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
          body: formdata,
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        setTimeout(
          () => thunkApi.dispatch(Reset_update_unit_comment_by_id()),
          2000
        );
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

//unit

export const get_all_units_by_subject_id_with_auth = createAsyncThunk(
  "Units/get_all_units_by_subject_id_with_auth",
  async ({ SubjectId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_all_units_by_subject_id?subject_id=${SubjectId}&limit=10`,
        {
          headers: { Authorization: `Bearer ${SIGN.token}` },
        }
      );
      const { data } = await res.json();
      if (res.ok) {
   
        return data;
      } else {

        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {

      return thunkApi.rejectWithValue(
        error.message === "Failed to fetch"
          ? "لا يوجد وحدات لعرضها"
          : error.message
      );
    }
  }
);

export const delete_unit_by_id = createAsyncThunk(
  "Units/delete_unit_by_id",
  async ({ UnitId, SubjectId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delete_last_unit/${UnitId}?subject_id=${SubjectId}`,
        { method: "DELETE", headers: { Authorization: `Bearer ${SIGN.token}` } }
      );
      const data = await res.json();
      if (res.ok) {
        thunkApi.dispatch(get_all_units_by_subject_id_with_auth({ SubjectId }));
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const get_unit_details_by_unit_id = createAsyncThunk(
  "Units/get_unit_details_by_unit_id",
  async ({ UnitId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_unit_details_by_unit_id/${UnitId}?limit=5`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${SIGN.token}`,
          },
        }
      );
      const { data } = await res.json();
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {


      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  // unit faqs states

  add_unit_faqs: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  delete_unit_faqs_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  update_unit_faqs_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  get_faqs_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  // unit comment states

  delete_comment_for_unit: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  update_comment_for_unit: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  get_unit_comment_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  // unit states

  get_unit_details_by_unit_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  delete_unit_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  get_all_units_by_subject_id_with_auth: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
};

export const UnitsSlice = createSlice({
  name: "Units",
  initialState,
  reducers: {
    Reset_update_unit_comment_by_id: (state) => {
      state.update_comment_for_unit.isSuccess = false;
    },
    clear: (state) => {
      state.update_unit_faqs_by_id.data = null;
    },
    clear2: (state) => {
      state.add_unit_faqs.data = null;
    },
  },

  extraReducers: {
    // unit comments Reducers

    [delete_comment_for_unit.pending]: (state, action) => {
      state.delete_comment_for_unit.isFetching = true;
      state.delete_comment_for_unit.isError = false;
      state.delete_comment_for_unit.errorMessage = null;
    },
    [delete_comment_for_unit.fulfilled]: (state, action) => {
      state.delete_comment_for_unit.isFetching = false;
      state.delete_comment_for_unit.isSuccess = true;
      state.delete_comment_for_unit.data = action.payload;
    },
    [delete_comment_for_unit.rejected]: (state, action) => {
      state.delete_comment_for_unit.isFetching = false;
      state.delete_comment_for_unit.isError = true;
      state.delete_comment_for_unit.errorMessage = action.payload;
    },

    [get_unit_comment_by_id.pending]: (state, action) => {
      state.get_unit_comment_by_id.isFetching = true;
      state.get_unit_comment_by_id.isError = false;
      state.get_unit_comment_by_id.errorMessage = null;
    },
    [get_unit_comment_by_id.fulfilled]: (state, action) => {
      state.get_unit_comment_by_id.isFetching = false;
      state.get_unit_comment_by_id.isSuccess = true;
      state.get_unit_comment_by_id.data = action.payload;
    },
    [get_unit_comment_by_id.rejected]: (state, action) => {
      state.get_unit_comment_by_id.isFetching = false;
      state.get_unit_comment_by_id.isError = true;
      state.get_unit_comment_by_id.errorMessage = action.payload;
    },

    // update unit comment by id

    [update_comment_for_unit.pending]: (state, action) => {
      state.update_comment_for_unit.isFetching = true;
      state.update_comment_for_unit.isError = false;
      state.update_comment_for_unit.errorMessage = null;
    },
    [update_comment_for_unit.fulfilled]: (state, action) => {
      state.update_comment_for_unit.isFetching = false;
      state.update_comment_for_unit.isError = false;
      state.update_comment_for_unit.errorMessage = null;
      state.update_comment_for_unit.data = action.payload;
      state.update_comment_for_unit.isSuccess = true;
    },
    [update_comment_for_unit.rejected]: (state, action) => {
      state.update_comment_for_unit.isFetching = false;
      state.update_comment_for_unit.isError = true;
      state.update_comment_for_unit.errorMessage = action.payload;
      state.update_comment_for_unit.data = null;
    },

    // unit faqs Reducers

    [add_unit_faqs.pending]: (state, action) => {
      state.add_unit_faqs.isFetching = true;
      state.add_unit_faqs.isError = false;
      state.add_unit_faqs.errorMessage = null;
    },
    [add_unit_faqs.fulfilled]: (state, action) => {
      state.add_unit_faqs.isFetching = false;
      state.add_unit_faqs.isSuccess = true;
      state.add_unit_faqs.data = action.payload;
    },
    [add_unit_faqs.rejected]: (state, action) => {
      state.add_unit_faqs.isFetching = false;
      state.add_unit_faqs.isError = true;
      state.add_unit_faqs.errorMessage = action.payload;
    },

    [delete_unit_faqs_by_id.pending]: (state, action) => {
      state.delete_unit_faqs_by_id.isFetching = true;
      state.delete_unit_faqs_by_id.isError = false;
      state.delete_unit_faqs_by_id.errorMessage = null;
    },
    [delete_unit_faqs_by_id.fulfilled]: (state, action) => {
      state.delete_unit_faqs_by_id.isFetching = false;
      state.delete_unit_faqs_by_id.isSuccess = true;
      state.delete_unit_faqs_by_id.data = action.payload;
    },
    [delete_unit_faqs_by_id.rejected]: (state, action) => {
      state.delete_unit_faqs_by_id.isFetching = false;
      state.delete_unit_faqs_by_id.isError = true;
      state.delete_unit_faqs_by_id.errorMessage = action.payload;
    },

    [update_unit_faqs_by_id.pending]: (state, action) => {
      state.update_unit_faqs_by_id.isFetching = true;
      state.update_unit_faqs_by_id.isError = false;
      state.update_unit_faqs_by_id.errorMessage = null;
    },
    [update_unit_faqs_by_id.fulfilled]: (state, action) => {
      state.update_unit_faqs_by_id.isFetching = false;
      state.update_unit_faqs_by_id.isSuccess = true;
      state.update_unit_faqs_by_id.data = action.payload;
    },
    [update_unit_faqs_by_id.rejected]: (state, action) => {
      state.update_unit_faqs_by_id.isFetching = false;
      state.update_unit_faqs_by_id.isError = true;
      state.update_unit_faqs_by_id.errorMessage = action.payload;
    },

    [get_faqs_by_id.pending]: (state, action) => {
      state.get_faqs_by_id.isFetching = true;
      state.get_faqs_by_id.isError = false;
      state.get_faqs_by_id.errorMessage = null;
    },
    [get_faqs_by_id.fulfilled]: (state, action) => {
      state.get_faqs_by_id.isFetching = false;
      state.get_faqs_by_id.isSuccess = true;
      state.get_faqs_by_id.data = action.payload;
    },
    [get_faqs_by_id.rejected]: (state, action) => {
      state.get_faqs_by_id.isFetching = false;
      state.get_faqs_by_id.isError = true;
      state.get_faqs_by_id.errorMessage = action.payload;
    },
    // unit Reducers

    [get_unit_details_by_unit_id.pending]: (state, action) => {
      state.get_unit_details_by_unit_id.isFetching = true;
      state.get_unit_details_by_unit_id.isError = false;
      state.get_unit_details_by_unit_id.errorMessage = null;
    },
    [get_unit_details_by_unit_id.fulfilled]: (state, action) => {
      state.get_unit_details_by_unit_id.isFetching = false;
      state.get_unit_details_by_unit_id.isSuccess = true;
      state.get_unit_details_by_unit_id.data = action.payload;
    },
    [get_unit_details_by_unit_id.rejected]: (state, action) => {
      state.get_unit_details_by_unit_id.isFetching = false;
      state.get_unit_details_by_unit_id.isError = true;
      state.get_unit_details_by_unit_id.errorMessage = action.payload;
    },

    [delete_unit_by_id.pending]: (state, action) => {
      state.delete_unit_by_id.isFetching = true;
      state.delete_unit_by_id.isError = false;
      state.delete_unit_by_id.errorMessage = null;
    },
    [delete_unit_by_id.fulfilled]: (state, action) => {
      state.delete_unit_by_id.isFetching = false;
      state.delete_unit_by_id.isSuccess = true;
      state.delete_unit_by_id.data = action.payload;
    },
    [delete_unit_by_id.rejected]: (state, action) => {
      state.delete_unit_by_id.isFetching = false;
      state.delete_unit_by_id.isError = true;
      state.delete_unit_by_id.errorMessage = action.payload;
    },

    [get_all_units_by_subject_id_with_auth.pending]: (state, action) => {
      state.get_all_units_by_subject_id_with_auth.isFetching = true;
      state.get_all_units_by_subject_id_with_auth.isError = false;
      state.get_all_units_by_subject_id_with_auth.errorMessage = null;
    },
    [get_all_units_by_subject_id_with_auth.fulfilled]: (state, action) => {
      state.get_all_units_by_subject_id_with_auth.isFetching = false;
      state.get_all_units_by_subject_id_with_auth.isSuccess = true;
      state.get_all_units_by_subject_id_with_auth.data = action.payload;
    },
    [get_all_units_by_subject_id_with_auth.rejected]: (state, action) => {
      state.get_all_units_by_subject_id_with_auth.isFetching = false;
      state.get_all_units_by_subject_id_with_auth.isError = true;
      state.get_all_units_by_subject_id_with_auth.errorMessage = action.payload;
      state.get_all_units_by_subject_id_with_auth.data = null;
    },
  },
});
export const { Reset_update_unit_comment_by_id, clear, clear2 } =
  UnitsSlice.actions;
export const clearFaqq = (data) => {
  return {
    type: clear.type,
  };
};
export const clearFaqq2 = (data) => {
  return {
    type: clear2.type,
  };
};
export default UnitsSlice.reducer;
