
import React from 'react';
import axios from 'axios';
import './MarkAsReadBtn.css';

const MarkAsReadBtn = ({isRead,notificatoin_id, className,refetch}) => {

    const [disableBtn,setDisableBtn] = React.useState(false);
    const [permIsRead,setPermIsRead] = React.useState(isRead);

    const changeNotificationSeenStatus = (e,notificationId) => {
        // https://backend.myway.edu.sy/api/change_notification_status/491
        e.preventDefault();
        e.stopPropagation();

        // disable the button to prevent user form clicking it while the previous api call not response yet
        setDisableBtn(true);

        axios.post(`${process.env.REACT_APP_API_URL}/change_notification_status/${notificatoin_id}`,
        {},
        {headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }}
        )
        .then(data => {
            setPermIsRead(prev => !prev)
            // refetc/h();
            if(refetch){
                refetch();
            }
        })
        .catch(error => {
            // console.log(error)
        })
        .finally(() => {
            setDisableBtn(false);
        })

    }

    return (
        <button 
        className={`markAsRead shadow ${className} ${disableBtn ? 'bg-secondary' : ''}`} 
        onClick={(e) => changeNotificationSeenStatus(e,notificatoin_id)}
        title={permIsRead == 1 ? "تمييز كغير مقروءة" : "تمييز كمقروءة"}
        disabled={disableBtn}
        >

        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
        </svg>

        </button>
    )
}

export default MarkAsReadBtn;