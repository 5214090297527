import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// delete lesson comment

export const delete_lesson_comment_by_id = createAsyncThunk(
  "lesson/delete_lesson_comment_by_id",
  async ({ CommentId, Refetch }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${CommentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        Refetch();
        alert('تمت عملية الحذف بنجاح')
        return data;
      } else {
        alert('حدث خطأ ما يرجى المحاولة مرة اخرى');
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      alert('حدث خطأ ما يرجى المحاولة مرة اخرى');

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

//  get_lesson_comment_by_id

export const get_lesson_comment_by_id = createAsyncThunk(
  "lesson/get_lesson_comment_by_id",
  async ({ CommentId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_lesson_comment_by_id/${CommentId}`,
        {
          headers: { Authorization: `Bearer ${SIGN.token}` },
        }
      );
      const { data } = await res.json();
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// update lesson comment by id

export const update_lesson_comment_by_id = createAsyncThunk(
  "lesson/update_lesson_comment_by_id",
  async ({ formdata, CommentId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/update_lesson_comment_by_id/${CommentId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
          body: formdata,
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        setTimeout(
          () => thunkApi.dispatch(Reset_update_lesson_comment_by_id()),
          2000
        );
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const get_faqs_by_id = createAsyncThunk(
  "lesson/get_faqs_by_id",
  async ({ faqId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_faqs_by_id/${faqId}`,
        {
          headers: { Authorization: `Bearer ${SIGN.token}` },
        }
      );
      const { data } = await res.json();
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {

      return thunkApi.rejectWithValue(
        error.message === "Failed to fetch"
          ? "لا يوجد وحدات لعرضها"
          : error.message
      );
    }
  }
);

export const delete_lesson_faqs_by_id = createAsyncThunk(
  "lesson/delete_lesson_faqs_by_id",
  async ({ faqId, Refetch }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delete_faqs/${faqId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        Refetch();
        alert('تمت عملية الحذف بنجاح')
        return data;
      } else {
        alert('حدث خطأ ما يرجى المحاولة مرة اخرى')
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      alert('حدث خطأ ما يرجى المحاولة مرة اخرى')
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const add_lesson_faqs = createAsyncThunk(
  "lesson/add_lesson_faqs",
  async ({ formdata, reset }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/add_faqs`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${SIGN.token}`,
        },
        body: formdata,
      });
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        reset();
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {

      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const update_lesson_faqs_by_id = createAsyncThunk(
  "lesson/update_lesson_faqs_by_id",
  async ({ formdata, faqId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/update_faqs/${faqId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
          },
          body: formdata,
        }
      );
      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const get_all_lessons_by_unit_id_with_auth = createAsyncThunk(
  "lesson/get_all_lessons_by_unit_id_with_auth",
  async ({ UnitId }, thunkApi) => {
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_all_lessons_by_unit_id?limit=10&unit_id=${UnitId}`,
        {
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
  
      const data = await res.json();
      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const get_lesson_by_lesson_id = createAsyncThunk(
  "lesson/get_lesson_by_lesson_id",
  async ({ LessonId }, thunkApi) => {
   
    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/get_lesson_by_lesson_id/${LessonId}`,
        {
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const isJson = res.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await res.json() : null;

      if (res.ok) {
        return data;
      } else {
        return thunkApi.rejectWithValue(
          data?.message ? data.message : ` رقم الخطأ ${res.status} `
        );
      }
    } catch (error) {
      
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const delete_last_lesson = createAsyncThunk(
  "lesson/delete_last_lesson",
  async ({ UnitId, LessonId }, thunkApi) => {

    const { SIGN } = thunkApi.getState();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delete_last_lesson/${LessonId}?unit_id=${UnitId}`,
        {
          headers: {
            Authorization: `Bearer ${SIGN.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "DELETE",
        }
      );

      const { data } = await res.json();
      if (res.ok) {
        thunkApi.dispatch(get_all_lessons_by_unit_id_with_auth({ UnitId }));
        return data;
      } else {
        return thunkApi.rejectWithValue(data.message);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.message === "Unexpected end of JSON input"
          ? "لا يوجد دروس"
          : error.message
      );
    }
  }
);

const initialState = {
  // comments lesson

  // delete comment lesson
  delete_lesson_comment_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  // get lesson comment by id
  get_lesson_comment_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  // update lesson comment by id
  update_lesson_comment_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  // lesson faqs

  add_lesson_faqs: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  delete_lesson_faqs_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  get_faqs_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
  update_lesson_faqs_by_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  get_all_lessons_by_unit_id_with_auth: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  delete_last_lesson: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },

  get_lesson_by_lesson_id: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  },
};

export const LessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    Reset_update_lesson_comment_by_id: (state) => {
      state.update_lesson_comment_by_id.isSuccess = false;
    },
    clear: (state) => {
      state.add_lesson_faqs.isFetching = false;
      state.add_lesson_faqs.data = null;
      state.add_lesson_faqs.isSuccess = false;
    },
  },
  extraReducers: {
    // get lesson comment by id

    [get_lesson_comment_by_id.pending]: (state, action) => {
      state.get_lesson_comment_by_id.isFetching = true;
      state.get_lesson_comment_by_id.isError = false;
      state.get_lesson_comment_by_id.errorMessage = null;
    },
    [get_lesson_comment_by_id.fulfilled]: (state, action) => {
      state.get_lesson_comment_by_id.isFetching = false;
      state.get_lesson_comment_by_id.isError = false;
      state.get_lesson_comment_by_id.errorMessage = null;
      state.get_lesson_comment_by_id.data = action.payload;
      state.get_lesson_comment_by_id.isSuccess = true;
    },
    [get_lesson_comment_by_id.rejected]: (state, action) => {
      state.get_lesson_comment_by_id.isFetching = false;
      state.get_lesson_comment_by_id.isError = true;
      state.get_lesson_comment_by_id.errorMessage = action.payload;
      state.get_lesson_comment_by_id.data = null;
    },

    // update lesson comment by id

    [update_lesson_comment_by_id.pending]: (state, action) => {
      state.update_lesson_comment_by_id.isFetching = true;
      state.update_lesson_comment_by_id.isError = false;
      state.update_lesson_comment_by_id.errorMessage = null;
    },
    [update_lesson_comment_by_id.fulfilled]: (state, action) => {
      state.update_lesson_comment_by_id.isFetching = false;
      state.update_lesson_comment_by_id.isError = false;
      state.update_lesson_comment_by_id.errorMessage = null;
      state.update_lesson_comment_by_id.data = action.payload;
      state.update_lesson_comment_by_id.isSuccess = true;
    },
    [update_lesson_comment_by_id.rejected]: (state, action) => {
      state.update_lesson_comment_by_id.isFetching = false;
      state.update_lesson_comment_by_id.isError = true;
      state.update_lesson_comment_by_id.errorMessage = action.payload;
      state.update_lesson_comment_by_id.data = null;
    },

    // add lesson faqs

    [add_lesson_faqs.pending]: (state, action) => {
      state.add_lesson_faqs.isFetching = true;
      state.add_lesson_faqs.isError = false;
      state.add_lesson_faqs.errorMessage = null;
    },
    [add_lesson_faqs.fulfilled]: (state, action) => {
      state.add_lesson_faqs.isFetching = false;
      state.add_lesson_faqs.isError = false;
      state.add_lesson_faqs.errorMessage = null;
      state.add_lesson_faqs.data = action.payload;
      state.add_lesson_faqs.isSuccess = true;
    },
    [add_lesson_faqs.rejected]: (state, action) => {
      state.add_lesson_faqs.isFetching = false;
      state.add_lesson_faqs.isError = true;
      state.add_lesson_faqs.errorMessage = action.payload;
      state.add_lesson_faqs.data = null;
    },

    // delete lesson faqs
    [delete_lesson_faqs_by_id.pending]: (state, action) => {
      state.delete_lesson_faqs_by_id.isFetching = true;
      state.delete_lesson_faqs_by_id.isError = false;
      state.delete_lesson_faqs_by_id.errorMessage = null;
    },
    [delete_lesson_faqs_by_id.fulfilled]: (state, action) => {
      state.delete_lesson_faqs_by_id.isFetching = false;
      state.delete_lesson_faqs_by_id.isError = false;
      state.delete_lesson_faqs_by_id.errorMessage = null;
      state.delete_lesson_faqs_by_id.data = action.payload;
      state.delete_lesson_faqs_by_id.isSuccess = true;
    },
    [delete_lesson_faqs_by_id.rejected]: (state, action) => {
      state.delete_lesson_faqs_by_id.isFetching = false;
      state.delete_lesson_faqs_by_id.isError = true;
      state.delete_lesson_faqs_by_id.errorMessage = action.payload;
      state.delete_lesson_faqs_by_id.data = null;
    },

    // get lesson faqs by id
    [get_faqs_by_id.pending]: (state, action) => {
      state.get_faqs_by_id.isFetching = true;
      state.get_faqs_by_id.isError = false;
      state.get_faqs_by_id.errorMessage = null;
    },
    [get_faqs_by_id.fulfilled]: (state, action) => {
      state.get_faqs_by_id.isFetching = false;
      state.get_faqs_by_id.isSuccess = true;
      state.get_faqs_by_id.data = action.payload;
    },
    [get_faqs_by_id.rejected]: (state, action) => {
      state.get_faqs_by_id.isFetching = false;
      state.get_faqs_by_id.isError = true;
      state.get_faqs_by_id.errorMessage = action.payload;
    },

    //  update lesson faqs by id
    [update_lesson_faqs_by_id.pending]: (state, action) => {
      state.update_lesson_faqs_by_id.isFetching = true;
      state.update_lesson_faqs_by_id.isError = false;
      state.update_lesson_faqs_by_id.errorMessage = null;
    },
    [update_lesson_faqs_by_id.fulfilled]: (state, action) => {
      state.update_lesson_faqs_by_id.isFetching = false;
      state.update_lesson_faqs_by_id.isSuccess = true;
      state.update_lesson_faqs_by_id.data = action.payload;
    },
    [update_lesson_faqs_by_id.rejected]: (state, action) => {
      state.update_lesson_faqs_by_id.isFetching = false;
      state.update_lesson_faqs_by_id.isError = true;
      state.update_lesson_faqs_by_id.errorMessage = action.payload;
    },

    // get teacher lessons by unit

    [get_all_lessons_by_unit_id_with_auth.pending]: (state, action) => {
      state.get_all_lessons_by_unit_id_with_auth.isFetching = true;
      state.get_all_lessons_by_unit_id_with_auth.isError = false;
      state.get_all_lessons_by_unit_id_with_auth.errorMessage = null;
    },
    [get_all_lessons_by_unit_id_with_auth.fulfilled]: (state, action) => {
      state.get_all_lessons_by_unit_id_with_auth.isFetching = false;
      state.get_all_lessons_by_unit_id_with_auth.isError = false;
      state.get_all_lessons_by_unit_id_with_auth.errorMessage = null;
      state.get_all_lessons_by_unit_id_with_auth.data = action.payload;
    },
    [get_all_lessons_by_unit_id_with_auth.rejected]: (state, action) => {
      state.get_all_lessons_by_unit_id_with_auth.isFetching = false;
      state.get_all_lessons_by_unit_id_with_auth.isError = true;
      state.get_all_lessons_by_unit_id_with_auth.errorMessage = action.payload;
      state.get_all_lessons_by_unit_id_with_auth.data = null;
    },

    // get teacher lessons by unit

    [delete_last_lesson.pending]: (state, action) => {
      state.delete_last_lesson.isFetching = true;
      state.delete_last_lesson.isError = false;
      state.delete_last_lesson.errorMessage = null;
    },
    [delete_last_lesson.fulfilled]: (state, action) => {
      state.delete_last_lesson.isFetching = false;
      state.delete_last_lesson.isError = false;
      state.delete_last_lesson.errorMessage = null;
      state.delete_last_lesson.data = action.payload;
    },
    [delete_last_lesson.rejected]: (state, action) => {
      state.delete_last_lesson.isFetching = false;
      state.delete_last_lesson.isError = true;
      state.delete_last_lesson.errorMessage = action.payload;
      state.delete_last_lesson.data = null;
    },

    // get lesson by lesson id

    [get_lesson_by_lesson_id.pending]: (state, action) => {
      state.get_lesson_by_lesson_id.isFetching = true;
      state.get_lesson_by_lesson_id.isError = false;
      state.get_lesson_by_lesson_id.errorMessage = null;
    },
    [get_lesson_by_lesson_id.fulfilled]: (state, action) => {
      state.get_lesson_by_lesson_id.isFetching = false;
      state.get_lesson_by_lesson_id.isError = false;
      state.get_lesson_by_lesson_id.errorMessage = null;
      state.get_lesson_by_lesson_id.data = action.payload;
    },
    [get_lesson_by_lesson_id.rejected]: (state, action) => {
      state.get_lesson_by_lesson_id.isFetching = false;
      state.get_lesson_by_lesson_id.isError = true;
      state.get_lesson_by_lesson_id.errorMessage = action.payload;
      state.get_lesson_by_lesson_id.data = null;
    },
  },
});

export const { Reset_update_lesson_comment_by_id, clear } = LessonSlice.actions;
export const clearData = (data) => {
  return {
    type: clear.type,
  };
};
export default LessonSlice.reducer;
