import axios from "axios";
export default async function fetchQuizesBank({ queryKey }) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/bank-quizzes?
      search=${queryKey[2]}
      &page=${queryKey[1]}
        ${queryKey[3] != null ? `&subject_id=${queryKey[3]}` : ""}
        ${queryKey[4] != null ? `&unit_id=${queryKey[4]}` : ""}
        `,
    {
      params: { limit: 19 },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );

  return data;
}
