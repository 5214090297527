import { useState } from 'react';
import axios from 'axios';

import { useSelector } from 'react-redux';

export const GetData = (url) => {
	// const token = useSelector(state=>state.SIGN.token)
	// console.log(`${process.env.REACT_APP_API_SITE}${url}`)
	const token = localStorage.getItem('token');

	const [data, setData] = useState(null);
	const [isSuccess, setIsSuccess] = useState(false);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);
	const [IsError, setIsError] = useState('');
	const getDatafrom = async () => {
		setIsLoading(true);

		axios.withCredentials = true;

		setIsLoading(true);
		await axios
			.get(`${process.env.REACT_APP_API_URL}` + url, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',

					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
				},

				onUploadProgress: (progressEvent) => {
					const progress = Math.floor(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					setProgress(progress);
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					// console.log('the fucking url is => ', url);
					// console.log('sucess data da da da da',data);
					setIsError('');
					setIsSuccess(true);
					setProgress(0);
					setTimeout(() => setIsSuccess(false), 4000);

					return setData(data);
				}
			})
			.catch((error) => {
				setProgress(0);

				return setIsError(error);
			})
			.finally(() => setIsLoading(false));
	};

	return { getDatafrom, isSuccess, progress, IsLoading, IsError, data };
};
