import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFooter } from '../../../redux-toolkit/reducers/FooterInfo/FooterSlice'

export default function FooterLogic() {
  
    const dispatch = useDispatch();
    useEffect(()=>{
           dispatch(getFooter());
    },[])
    
    const homeData =  useSelector(state => state.home);
    const data =  homeData?.post?.data
   

    const socialInfo = useSelector(state=>state.footer);
    return {
        data,socialInfo
    }
}
