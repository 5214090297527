import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getUnitComment = createAsyncThunk(
	'UnitComment/getAllUnitComment',
	async (id = 1, thunkApi) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_all_comments_of_unit_by_unit_id/${id}?limit=10`
			);
			const data = await res.json();
			if (res.ok) {
			
				
				return data;
			} else {
		
				
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
		
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
};

export const UnitCommentSlice = createSlice({
	name: 'UnitComment',
	initialState,
	reducers: {},
	extraReducers: {
		[getUnitComment.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[getUnitComment.fulfilled]: (state, action) => {
			state.loading = false;
	
			state.post = action.payload;
		},
		[getUnitComment.rejected]: (state, action) => {
	
			state.loading = false;
			state.error = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default UnitCommentSlice.reducer;
