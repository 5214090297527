import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'
import elementicon from '../../../assets/icons/element-icon.png'
import FooterLogic from './FooterLogic'
import groupicon from '../../../assets/tareke-numbers/group.png'
import books_stack_of_three from '../../../assets/tareke-numbers/books-stack-of-three.png'
import facebook from "../../../assets/social_media_icons/facebook.png"
import youtube from "../../../assets/social_media_icons/youtube.png"
import whatsapp from "../../../assets/social_media_icons/whatsapp.png"
import instagram from "../../../assets/social_media_icons/instagram.png"
import logo from '../../../assets/logo/logo.png'
import chalks from  "../../../assets/opinion-student-of-tareke/chalks.png"
import {AiOutlineHome} from 'react-icons/ai'
import {SiParitysubstrate} from 'react-icons/si'
import {FcAbout} from 'react-icons/fc'
import {FcLibrary} from 'react-icons/fc';
import {MdPrivacyTip} from 'react-icons/md'
import {BsCodeSquare} from 'react-icons/bs';
import ICR from '../../../assets/icr/icr.png'

export default function Footer() {

  const getClickableLink = link => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `http://${link}`;
  }; 
const { data , socialInfo } = FooterLogic();
    
// console.log("data" , data);
  
const [numbers,setNumber] = useState()

  useEffect(() => {
    async function getData() {
      const actualData = await fetch(
      `${process.env.REACT_APP_API_URL}/get_home_site_info?local=ar`
      ).then(response => response.json());
      setNumber(actualData?.data);
  
    }
    getData()
  }, [])






  return (
    <>
      {/* start footer */}
<footer className="overflow-hidden footer-home">
    <div className="row justify-content-around">  
        <div className="footer-logo  d-flex justify-content-center  col-md-3 col-sm-10 col-xs-12 col-md-10 col-lg-3 mt-4">
            <div>
            <img loading="lazy"  src={logo} className='Footer_Main_logo'  alt=""   />
            <div className="d-flex flex-column  justify-content-center align-items-center">
        <h4 className="text-white p-4 fs-5 ">
            تواصلوا معنا
        </h4>
        <div className=" d-flex footer_icons flex-row  flex-wrap justify-content-center p-2 my-2" style={{width:'fit-content'}}>
                            <a href={socialInfo?.post?.data?.facebook}> <img loading="lazy"  src={facebook}   alt="facebook logo" className='p-1' style={{width:'70%',height:'auto'}}/> </a>
                            <a href={socialInfo?.post?.data?.instgram}> <img loading="lazy"  src={instagram} alt="instagram logo" className='p-1' style={{width:'70%',height:'auto'}}/> </a>
                            {/* <a href={socialInfo?.post?.data?.whatsapp}> <img loading="lazy"  src={whatsapp} alt="whatsapp logo" className='p-1' style={{width:'70%',height:'auto'}}/> </a> */}
                            <a href={socialInfo?.post?.data?.youtube}> <img loading="lazy"  src={youtube} alt="youtube logo" className='p-1' style={{width:'70%',height:'auto'}}/> </a>
        </div>
       </div>

            </div>
        </div>
        <div className="d-flex flex-column flex-wrap col-md-4 ">
                    <ul className="row p-4 flex-row flex-wrap justify-content-center mt-4">
                          
                            <div className='col-6'>
                            <li >
                                <NavLink to='/' className='d-flex align-items-center'>  <AiOutlineHome className='footer-icon'  />  الرئيسية </NavLink>
                            </li>
                            <li>
                                <NavLink to='/subscribtion' className='d-flex align-items-center gap-1'> <SiParitysubstrate className='footer-icon'  />  الاشتراكات </NavLink>
                            </li>
                            <li>
                                <NavLink to='/AboutUs' className='d-flex align-items-center gap-1'> <BsCodeSquare className='footer-icon'  /> لمحة عنا  </NavLink>
                            </li>
                          
                          
                            </div>
                            <div className='col-6'>
                            {/* <li >
                                <NavLink to='/TermsandConditions'> <img loading="lazy"  alt='element' src={elementicon}  />  الأحكام والقوانين  </NavLink>
                            </li> */}
                            <li>
                                <NavLink to='/TermsandConditions' className='d-flex align-items-center'>  <MdPrivacyTip className='footer-icon'  />  سياسة الخصوصية </NavLink>
                            </li>
                           
                            <li>
                            <NavLink to='/LibrarySite' className='d-flex align-items-center gap-1'>  <FcLibrary className='footer-icon'  />المكتبه </NavLink>
                            </li>
                          
                            </div>
                            
                    </ul>
                  </div>

        
         <div className="col-md-3 footer-numbers-tareke-images-container  text-center p-4 " >
                <h3 className="footer-numbers-section mb-4">
                    <img loading="lazy"  alt='chalks' src={chalks}  />
                    أرقام منصة طريقي
                </h3>
                <div  className="row justify-content-around ">
                     <div className="col-4 " >
                        <img loading="lazy"  alt='group' src={groupicon} width="50%" />
                        <h2 className='fs-4'> {numbers?.number_of_students} +</h2>
                        <p> طالب وطالبة</p>
                     </div>
                     <div className="col-4" >
                        <img loading="lazy"  alt='books-stack-of-three' src={books_stack_of_three} width="50%" />
                        <h2 className='fs-4'> {numbers?.number_of_lessons} +</h2>
                        <p> درس ومحاضرة</p>
                     </div>
                     <div className="col-4 " >
                        <img loading="lazy"  alt='communication' src={books_stack_of_three} width="50%" /> 
                        <h2 className='fs-4'> {numbers?.number_of_subjects} +</h2>
                        <p> مادة</p>
                     </div>
                </div>
         </div>


        <div className='icr mb-1 d-flex justify-content-center align-items-center '>
        <a className='d-flex justify-content-center align-items-center' href='https://www.facebook.com/ICRCompanyPro'>
            <p className='mt-1'>تم تصميم وتطوير المنصة بواسطة شركة </p>
            <img loading="lazy"  src={ICR} width='60' height='60'/>
        </a>
            </div>
            <div className='d-flex justify-content-center align-items-center text-center mb-1'>
                 <h5><a href={getClickableLink('ideacodereality.icr@gmail.com')} ><p className='my-2'>للتواصل :</p>  IdeaCodeReality.ICR@gmail.com</a></h5>
        </div>
    </div>

  

</footer>

      {/* end footer */}
    </>
  );
}
