import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getpoints = createAsyncThunk(
	'opinion/getAllopinion',
	async (_, thunkApi) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_student_points`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			);

			const data = await res.json();
			if (res.ok) {

				return data;
			} else {
				
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

const initialState = {
	points: null,
	loading: false,
	error: null,
};

export const GetPointsSlice = createSlice({
	name: 'opinion',
	initialState,
	reducers: {},
	extraReducers: {
		[getpoints.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[getpoints.fulfilled]: (state, action) => {
			state.loading = false;

			state.points = action.payload;
		},
		[getpoints.rejected]: (state, action) => {

			state.loading = false;
			state.error = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default GetPointsSlice.reducer;
