import React, { useState, useEffect } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Header.css";
import HeaderLogic from "./HeaderLogic";

import logo from "../../../assets/logo/logo.png";
import faq from "../../../assets/faq.svg";
import navigation from "../../../assets/header/notification.png";
import userlogo from "../../../assets/header/user.png";
import Notify from "../../../components/Notify/Notify";
import axios from "axios";
import uuid from "react-uuid";

export default function Header() {
  const { statusAuth } = HeaderLogic();
  const navigate = useNavigate();
  const [text, setText] = useState(
    "تهانينا لقد تم تصحيح الاختبار الخارق الخاص بك وحصلت على 30 نقطة"
  );
  const [notifications, setNotifications] = useState([]);
  const [ShowNotifications, setShowNotifications] = useState(false);
  const [noticationsRef, setNotificationsRef] = useState(null);
  // const [intervalForNotifications,setIntervalForNotificaions] = useState();
  // const [homeRoute,setHomeRoute] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function backendLoggingOut(token) {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {})
      .catch((error) => {})
      .finally();
  }

  const handlelogout = (e) => {
    e.preventDefault();
    const token =
      localStorage.getItem("token") || localStorage.getItem("guardian_token");
    backendLoggingOut(token);
    window.location.reload();
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("admin_as_teacher");
    localStorage.removeItem("is_active");
    localStorage.removeItem("guardian_token");
    if (localStorage.getItem("token2")) {
      localStorage.setItem("token", localStorage.getItem("token2"));
      localStorage.setItem("role", localStorage.getItem("role2"));
      localStorage.removeItem("token2");
      localStorage.removeItem("role2");
    }

    window.location.replace("/");
  };

  const toggleNotifications = () => {
    setShowNotifications((show) => !show);
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      // console.log(e.target)
      setShowNotifications(false);
      // if(e.target.getAttribute('id') == 'notification_container') {
      // 	setShowNotifications(true);
      // }else {a
      // 	setShowNotifications(false);
      // }
    });

    document.addEventListener("click", function handleClickOutsideBox(event) {
      const box = document.getElementById("notification_container");
      if (!box?.contains(event.target)) {
        //   box.style.display = 'none';
        setShowNotifications(false);
      }
    });
  }, []);

  const getNotificationsData = () => {
    const role_id = localStorage.getItem("role");

    axios
      .get(`${process.env.REACT_APP_API_URL}/get_user_notification?limit=8`, {
        headers: {
          Authorization: `Bearer ${
            role_id == 3
              ? localStorage.getItem("guardian_token")
              : localStorage.getItem("token")
          }`,
        },
      })
      .then((data) => {
        setNotifications(data.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // get notification data for the first time
    getNotificationsData();

    // if there is a user then get notifications for him
    if (localStorage.getItem("role")) {
      let intervalForNotifications;
      intervalForNotifications = setInterval(getNotificationsData, 120000);
      // clean up the interval to skip memory leakes
      return () => {
        clearInterval(intervalForNotifications);
      };
    }
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      setIsLoading(false);
    }
    if (notifications.length == 0) {
      setIsLoading(true);
    }
  }, [notifications]);

  const notificationState = (notification) => {
    // in case of student
    if (localStorage.getItem("role") == 1) {
      switch (notification.state) {
        case 1:
          return `/myquizes/lessons/automatedquiz/${notification.needed_id}`;
        case 2:
          return `/subjects/${notification.subject_id}/chat/participants/${notification.model_id}`;

        default:
          return "";
      }
    }

    // in case of teacher
    if (localStorage.getItem("role") == 2) {
      switch (notification.state) {
        case 1:
          return `/options/quizes/typequiz/${notification.student_id}/lessons/automatedquiz/${notification.needed_id}`;
        case 2:
          return `/subjects/${notification.subject_id}/chat/participants/${notification.model_id}`;

        default:
          return "";
      }
    }

    if (localStorage.getItem("role") == 3) {
      switch (notification.clickable) {
        case 1:
          return "/profileStudentGuardian";
          // return 	`/options/quizes/typequiz/${notification.student_id}/lessons/automatedquiz/${notification.needed_id}`
          break;

        default:
          return "";
      }
    }
  };

  return (
    <>
      {/* <Link to='/faq' className='faqs_btn_container' >
			<div className='d-relative content'>
				<img src={faq} width={80} height={80} className="shadow"/>
				<span className='questions_word'>الأسئلة</span>
				<span className='freq_word'>الشائعة</span>
			</div>
		</Link> */}

      <nav className="navbar navbar-expand-lg navbar-light bg-white text-center border-bottom">
        {/* main header */}

        {/* not home route and logged in as student */}

        <div className="container-fluid p-0">
          <NavLink className="navbar-brand m-0 order-first LOGO" to="/">
            <img
              loading="lazy"
              src={logo}
              alt=""
              width="80"
              height="45"
              className="d-inline-block   align-text-top"
            />
          </NavLink>
          <button
            className="navbar-toggler Toggler_Icon"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav w-100 justify-content-around  ">
              <li className="nav-item">
                <NavLink
                  className="nav-link  fs-5  "
                  aria-current="page"
                  to="/"
                >
                  الرئيسية
                </NavLink>
              </li>

              {
                <li className="nav-item">
                  <NavLink className="nav-link fs-5" to="/sections">
                    الأقسام
                  </NavLink>
                </li>
              }
              {localStorage.getItem("role") == 1 && (
                <li className="nav-item">
                  <NavLink className="nav-link fs-5" to="/mainquizes">
                    المذاكرات
                  </NavLink>
                </li>
              )}

              <li className="nav-item">
                <NavLink className="nav-link fs-5" to="/LibrarySite">
                  مكتبة الموقع
                </NavLink>
              </li>
              {!localStorage.getItem("temp") && (
                <li className="nav-item">
                  <NavLink className="nav-link fs-5" to="/offers">
                    العروض
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink className="nav-link fs-5" to="/AboutUs">
                  لمحة عنا
                </NavLink>
              </li>

              {/* {
								localStorage.getItem('role') == '1' &&
								<li className='nav-item mx-2'>
										<NavLink
											className='nav-link sign rounded-pill text-white fs-5 px-4 '
											to='/registeration'>
											 سجل الآن
										</NavLink>
									</li>
							} */}

              {!localStorage.getItem("role") && (
                <>
                  <div
                    style={{ width: "fit-content" }}
                    className="d-flex flex-lg-row signparent  flex-column justify-content-center  justify-content-lg-start "
                  >
                    <li className="nav-item mx-2">
                      <NavLink
                        className="nav-link sign rounded-pill text-white fs-5 px-4 "
                        to="/signup"
                      >
                        إنشاء حساب
                      </NavLink>
                    </li>
                    <li className="nav-item mx-2">
                      <NavLink
                        className="nav-link sign rounded-pill text-white fs-5 px-4 sign_in "
                        to="/signin"
                      >
                        تسجيل الدخول إلى المنصة
                      </NavLink>
                    </li>
                  </div>
                </>
              )}
            </ul>
          </div>

          {localStorage.getItem("role") && (
            <>
              <div className="d-flex order-first order-lg-last flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <div
                  // to='/notification'
                  className="d-block link-dark text-decoration-none notifications_icon"
                >
                  <div
                    className="notification mx-4"
                    id={"notification_container"}
                    onClick={() => toggleNotifications()}
                  >
                    {notifications &&
                      notifications?.result != "0" &&
                      notifications?.result != null && (
                        <div
                          className="padge_notifications shadow bg-danger"
                          style={{
                            display:
                              notifications?.result == "0" ? "none" : "flex",
                          }}
                        >
                          <span style={{fontSize: '13px'}}>{notifications?.result>99?'+99':notifications?.result}</span>
                        </div>
                      )}

                    <img
                      loading="lazy"
                      src={navigation}
                      alt="mdo"
                      width="28"
                      height="auto"
                      className="rounded-circle"
                    />

                    <div
                      id={"notification_container"}
                      // style={{display: 'block'  }}

                      className={`notifications_container shadow rounded ${
                        ShowNotifications
                          ? "showNotifications"
                          : "hideNotifications"
                      }`}
                      // className={`notifications_container shadow rounded'}`}
                    >
                      <div className="p-2">
                        <h4 className="text-end">الإشعارات</h4>
                      </div>

                      {isLoading && (
                        <div className="text-center my-3 w-100">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}

                      {notifications?.data?.data?.length == 0 && (
                        <p className="text-center my-3">لا يوجد إشعارات بعد</p>
                      )}

                      {notifications &&
                        notifications?.data?.data
                          ?.slice(0, 5)
                          .map((notification, index) => {
                            return (
                              <Notify
                                key={uuid()}
                                text={notification.message}
                                image={
                                  process.env.REACT_APP_STORAGE_URL +
                                  notification.sender_image
                                }
                                status={notification.state}
                                isRead={notification.has_seen}
                                linkto={notificationState(notification)}
                                timeStamp={notification.created_at}
                                notificatoin_id={notification.id}
                                refetch={getNotificationsData}
                                student_id={notification.student_id}
                              />
                            );
                          })}

                      {!isLoading ? (
                        <Link
                          to="/notification"
                          className="w-100 btn btn btn-dark w-100 rounded-0 mb-2"
                        >
                          عرض الكل
                        </Link>
                      ) : (
                        <div className="w-100 btn btn btn-dark w-100 rounded-0 mb-2">
                          عرض الكل
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="dropdown user  mx-1 ">
                  <NavLink
                    to="/"
                    className="d-block link-dark text-decoration-none dropdown-toggle"
                    id="user"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      loading="lazy"
                      src={userlogo}
                      alt="mdo"
                      width="28"
                      height="auto"
                      className="rounded-circle"
                    />
                  </NavLink>
                  <ul
                    className="dropdown-menu text-small "
                    aria-labelledby="user"
                  >
                    {/* {localStorage.getItem('role')==2 && <li ><NavLink className="dropdown-item " to="/options"></NavLink></li>}techerProfile */}
                    {localStorage.getItem("role") == 2 && (
                      <li>
                        <NavLink className="dropdown-item " to="/options">
                          البروفايل الخاص بي
                        </NavLink>
                      </li>
                    )}

                    {localStorage.getItem("role") == 1 && (
                      <li>
                        <NavLink
                          className="dropdown-item "
                          to="/studentprofile"
                        >
                          البروفايل
                        </NavLink>
                      </li>
                    )}
                    {localStorage.getItem("role") == 1 && (
                      <li>
                        <NavLink className="dropdown-item" to="/profileStudent">
                          لوحة التحكم
                        </NavLink>
                      </li>
                    )}

                    {localStorage.getItem("role") == 3 && (
                      <li>
                        <NavLink className="dropdown-item " to="/profile">
                          لوحة التحكم الخاصه بي
                        </NavLink>
                      </li>
                    )}
                    {localStorage.getItem("role") == 3 && (
                      <li>
                        <NavLink
                          className="dropdown-item "
                          to="/profileStudentGuardian"
                        >
                          البروفايل الخاص بإبني
                        </NavLink>
                      </li>
                    )}
                    {/* {localStorage.getItem('role')==} */}
                    {/* <li ><NavLink className="dropdown-item" to="/offers">العروض</NavLink></li> */}
                    <li>
                      <button onClick={handlelogout} className="dropdown-item">
                        تسجيل الخروج
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>

      {/* end nav bar  */}
    </>
  );
}
