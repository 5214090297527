import { lazy } from 'react';
import Loadable from '../../components/Loadable/Loadable';
import PrimaryLayout from '../../Layouts/PrimaryLayout/PrimaryLayout';

const ProfileStudentGuardian =  Loadable( lazy(() => import('../pages/ProfileStudentGuardian/ProfileStudentGuardian'))) ;
const ProfileStudentForGuardian =  Loadable( lazy(() => import('../pages/profileStudentForGuardian/index'))) ;
const Profile =  Loadable( lazy(() => import('../pages/Profile/Profile'))) ;
const Offers =  Loadable( lazy(() => import('../pages/Offers/Offers'))) ;
const PofileGuardian =  Loadable( lazy(() => import('../pages/profileGuardian/ProfileGuardian'))) ;
const Reports =  Loadable( lazy(() => import('../pages/Reports/Reports'))) ;
const SubjectsReports =  Loadable( lazy(() => import('../pages/SubjectsReports/SubjectsReports'))) ;
const UnitsReports =  Loadable( lazy(() => import('../pages/UnitsReports/UnitsReports'))) ;
const UnitReportDetails =  Loadable( lazy(() => import('../pages/UnitReportDetails/UnitReportDetails'))) ;
const LessonQuizeReports =  Loadable( lazy(() => import('../pages/LessonQuizeReports/LessonQuizeReports'))) ;
const UnitQuizReport =  Loadable( lazy(() => import('../pages/UnitQuizReports/UnitQuizReports'))) ;
const UnitAutomatedReports =  Loadable( lazy(() => import('../pages/UnitAutomatedReports/UnitAutomatedReports'))) ;
const UnitTraditionalReports =  Loadable( lazy(() => import('../pages/UnitTraditionalReports/UnitTraditionalReports'))) ;


const GuardianRoutes = [
    {
        path:'/',
        element: <PrimaryLayout/>,
        children: [
            {
                path: 'profileStudentGuardian',
                element: <ProfileStudentGuardian />
            },
            {
                path: 'profileStudentforGuardian',
                element: <ProfileStudentForGuardian />
            },
            {
                path: 'Profile',
                element: <Profile/>
            },
            {
                path: 'offers',
                element: <Offers/>
            },
            {
                path: 'profileGuardian',
                element: <PofileGuardian/>
            },
            {
                path: 'reports',
                element: <Reports />
            },
            {
                path: 'subjectsReports/:studentID',
                element: <SubjectsReports />
            },
            {
                path: 'unitsReports/:subjectId',
                element: <UnitsReports />
            },
            {
                path: 'unitReportDetails/:unitId',
                element: <UnitReportDetails />
            },
            {
                path: 'lessonsquizreports/:unitId',
                element: <LessonQuizeReports />
            },
            {
                path: 'unitquizreports/:unitId',
                element: <UnitQuizReport />
            },
            {
                path: 'unitautomatedreport/:unitId',
                element: <UnitAutomatedReports />
            },
            {
                path: 'unittraditionalreports/:unitId',
                element: <UnitTraditionalReports />
            }
        ]
    },


];

export default GuardianRoutes;
