
import PrimaryLayout from '../../Layouts/PrimaryLayout/PrimaryLayout';
import './Loader.css';
// ==============================|| LOADER ||============================== //
const Loader = () => (
    <div className='loaderWrapper'>
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
);

export default Loader;
