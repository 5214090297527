// ==============================|| ROUTING RENDER ||============================== //
import React from "react";
import { useRoutes } from "react-router-dom";
import AudienceRoutes from "../audience/routes/index";
import StudentRoutes from "../student/routes/index";
import TeacherRoutes from "../teacher/routes/index";
import GuardianRoutes from "../guardian/routes/index";
import { useLocation } from "react-router-dom";


export default function ThemeRoutes() {
  const location = useLocation();

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 1);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 50);
  }, [location]);

  let routes = useRoutes([
    ...AudienceRoutes,
    ...StudentRoutes,
    ...TeacherRoutes,
    ...GuardianRoutes,
   
  ]);

  return routes;
}
