import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import fetchQuizesBank from "../../redux-toolkit/api/fetchQuizesBank";
import { delete_automated_quiz_by_automated_quiz } from "../../redux-toolkit/reducers/Unit_test/UnitSlice";
import Error from "../alerts/Error/Error";

import Pagination from "../Pagination/Pagination";
import ToastError from "../ToastSuccessError/ToastError";
import ShowallautomatedfinalQuizLogic from "./ShowallautomatedfinalQuizLogic";

export default function ShowallautomatedfinalQuiz() {
  const {
    IsError,
    dispatch,
    final_automated_quiz_by_unit_id,
    IsLoading,
    getDatafrom,
  } = ShowallautomatedfinalQuizLogic();
  // console.log(final_automated_quiz_by_unit_id);

  const [selectedLesson , setSelectedLesson] = useState({})
  const createQuizModalRef = useRef(null);
  const [query,setQuery] = useState('');
  const [selectedQuiz,setSelectedQuiz] = useState({});
  const [selectedQuizId,setSelectedQuizId] = useState(null);
  const [isCloning, setIsCloning] = useState(false);
  const [successMessage,setSuccessMessage] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const cloneQuizRef = useRef(null);

  const [page,setPage] = useState(0);
  const {
    data: quiesBankData , 
    isLoading: isLoadingQuizesBank , 
    isError} = useQuery(['quiz_questions' , page , query] , fetchQuizesBank)


  function handlePageClick (data)
  {
      setPage(data.selected);
  }

  const handleSearchStudent = (e) => {
    const value = e.target.value;
    setQuery(value)
  } 

  const devouncedFn = debounce(handleSearchStudent , 500);

  const handleCloneQuiz = () => {
    //delete_automated_quiz/13
    setIsCloning(true); 

    const dataToSend = {
      cloned_automated_quiz_id: selectedQuiz.id, 
      new_automated_quiz_id: selectedQuizId
    }

    axios.post(`${process.env.REACT_APP_API_URL}/automated-quiz/clone`,dataToSend,
    {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    .then(data => {
      setSuccessMessage("تم  نسخ الأسئلة بنجاح")

      cloneQuizRef.current.style.display = 'none';
      cloneQuizRef.current.click();

    })
    .catch(error => {
      setErrorMessage('حدث خطأ ما يرجى المحاولة لاحقا')
    })
    .then(() => {
      setIsCloning(false);
    })
  }


  return (
    <div className="container">
        <ToastError 
      ErrorMessage={errorMessage}
      successMessage={successMessage}
      setErrorMessage={setErrorMessage}
      setSuccessMessage={setSuccessMessage}
    />

      {IsLoading && (
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {IsError && (
        <Error
          errorText={
            IsError?.response?.data?.message
              ? IsError?.response?.data?.message
              : IsError.message
          }
        />
      )}

      {
        <div class="table-responsive" style={{margin: '20px auto !important',
          width: 'fit-content'}}>
          <table className=" d-block table table-bordered table-striped">
            <thead>
              <tr>
                <th> اسم الاختبار </th>
                <th> مدة الاختبار </th>
                <th> نقاط الاختبار </th>
              </tr>
            </thead>
            {final_automated_quiz_by_unit_id?.data && (
              <tbody>
                {final_automated_quiz_by_unit_id?.data?.map((Quiz) => (
                  <tr key={Quiz.id}>
                    <td> {Quiz.quiz_name} </td>
                    <td> {Quiz.quiz_duration} </td>
                    <td> {Quiz.quiz_points} </td>
                    <td>
                      {" "}
                      <Link to={`edit/${Quiz.id}`} className="btn btn-dark">
                        {" "}
                        تعديل{" "}
                      </Link>{" "}
                    </td>
                    <td>
                      {" "}
                      <button
                        onClick={() =>
                          dispatch(
                            delete_automated_quiz_by_automated_quiz({
                              QuizId: Quiz.id,
                            })
                          ).then(() => {
                            getDatafrom();
                          })
                        }
                        className="btn btn-danger"
                      >
                        {" "}
                        حذف{" "}
                      </button>{" "}
                    </td>
                    <td>
                      {" "}
                      <button
                        // to={`Add_automated_quiz_questions/${Quiz.id}`}
                        className="btn btn-success"
                        onClick={() => {setSelectedLesson(Quiz) ; setSelectedQuizId(Quiz.id)}}
                        data-bs-toggle="modal" data-bs-target="#exampleModalCreatequiz"
                      >
                        {" "}
                        أضف أسئلة{" "}
                      </button>{" "}
                    </td>
                    <td>
                      {" "}
                      <Link
                        to={`show_automated_quiz_questions_options/${Quiz.id}`}
                        className="btn btn-success"
                      >
                        {" "}
                        عرض الأسئلة{" "}
                      </Link>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          <Link className="btn btn-success" to="create_unit_automated_quiz">
            أضف اختبار
           
          </Link>

          <div style={{margin: '30px auto'}}/>
          {/* {data?.data?.total < 10  ? <> </> : <Pagination  data={data} handlePageClick={handlePageClick} page={page} />  }   */}
        </div>
      }


      <div ref={createQuizModalRef} class="modal fade " id="exampleModalCreatequiz" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">الرجاء اختيار نوع نوع إنشاء الاختبار</h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body d-flex justify-content-between">
      <button 
      type="button" 
      class="btn btn-success"
      data-bs-toggle="modal" 
      data-bs-target="#exampleModalbank" 
      data-bs-whatever="@mdo"
      onClick={() => {
        createQuizModalRef.current.style.display = 'none';
          createQuizModalRef.current.click();
      }}
      >إنشاء نسخة عن اختبار من بنك الاختبارات</button>

        <Link 
        to={`Add_automated_quiz_questions/${selectedLesson.id}`} 
        onClick={() => {
          createQuizModalRef.current.style.display = 'none';
          createQuizModalRef.current.click();
        }}
        type="button" 
        class="btn btn-primary">إضافة أسئلة جديدة</Link>

      </div>
      <div class="modal-footer">
        <button 
        ref={createQuizModalRef} 
        type="button" 
        class="btn btn-danger" 
        data-bs-dismiss="modal">إلغاء</button>

      </div>
    </div>
  </div>
  </div>

      <div class="modal modal-fullscreen fade" ref={cloneQuizRef} id="exampleModalbank" tabindex="-1" aria-labelledby="exampleModalbank" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalbank">يرجى اختيار الاختبار المراد نسخ اسئلته </h5>
      </div>
      <div class="modal-body">


      <input 
        type='text' 
        placeholder='اكتب هنا للبحث عن اختبار عن طريق الاسم' 
        onChange={(e) => devouncedFn(e)} className='shadow w-100 main-input border-0 mb-4 rounded'
        />

      <div className='overflow-auto w-100'>
        <table className='table table-striped table-min-fit  table-hover shadow rounded border  mt-3'  style={{minHeight: '400px'}}> 

            <thead> 

                
                <tr className='align-middle table-head fw-bold'> 
                <th className='text-center align-middle'></th>
                <th className='text-center align-middle'> اسم الاختبار</th> 
                <th className='text-center align-middle'>  النقاط</th> 
                <th className='text-center align-middle'> المدة</th> 
                </tr> 

            </thead> 
        
            <tbody style={{minHeight: '400px'}}> 
 
                { isLoadingQuizesBank ? <td colSpan={4}> 
                <div className='w-100 text-center my-5 d-flex justify-content-center'>
                <div class="spinner-border" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}} role="status"> 
                     <span class="visually-hidden">Loading...</span> 
                   </div>
                   </div>
                   </td> :  
                 
                   quiesBankData && quiesBankData.data.map((quiz,index) => { 
                        return <tr 
                        key={index} 
                        style={{cursor: 'pointer',borderBottom: '1px solid #1a1a1a1a'}}
                        className="tableRowHover"
                        > 

                        <td className='text-center align-middle'>
                        <input 
                        type="checkbox"
                          checked={quiz.id == selectedQuiz?.id ? true : false}
                          // className='form-control'
                          onClick={() =>setSelectedQuiz(quiz)}
                        />
                        </td>
                        <td className='text-center align-middle'>
                        {quiz.quiz_name}
                        </td>
                        <td className='text-center align-middle'>
                        {quiz.quiz_points}
                        </td>
                        <td className='text-center align-middle'>
                        {quiz.quiz_duration}
                        </td>
                       
                        </tr> 
                 
                    }) 
                } 
                
            
            </tbody> 
        </table> 
        </div>

        <Pagination data={quiesBankData} page={page} handlePageClick={handlePageClick} />
        {
          selectedQuiz.quiz_name && 
          <div className="my-3">
          <button className="btn btn-success"
          onClick={() => handleCloneQuiz()}
          >
                إضافة نسخة من الاختبار {selectedQuiz.quiz_name}

                {
                  isCloning && 
                  <div className="mx-2">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </div>
                }
          </button>
        </div>
        }



        
      </div>
      <div class="modal-footer">
        <button type="button" ref={cloneQuizRef} class="btn btn-secondary" data-bs-dismiss="modal">إغلاق</button>
      </div>
    </div>
  </div>
</div>




    </div>
  );
}
