import React from "react";
import { IoMicOutline } from "react-icons/io5";

import rec from "../../../icons8-microphone.gif";
import AudioPlayer from "./AudioPlayer";

function RecorpPopup({
  setDelpopup,
  delpopup,
  startRecording,
  setRecord,
  audioBlob,
  setAudioBlob,
  isRecording,
  stopRecording,
  recordedChunks,
  sendMessage,
}) {
  return (
    <>
      <div className="">
        <div className="col-lg-4 col-md-8 col-xs-10 record_dev p-2 mr-2  ">
          <div className="col-12 d-flex justify-content-center align-items-center  ">
            <div className="col-12 mt-4 mb-3 ">
              <div className="d-flex justify-content-start align-items-center  ">
                <div className="col-3  d-flex justify-content-center align-items-center  curser   ">
                  {isRecording ? (
                    <img
                      src={rec}
                      alt=""
                      onClick={stopRecording}
                      className="img_rec"
                    />
                  ) : (
                    <IoMicOutline
                      color="red"
                      size={35}
                      onClick={startRecording}
                    />
                  )}
                </div>
                {isRecording ? (
                  <p className="rec_text"> جاري تسجيل الرسالة </p>
                ) : audioBlob ? (
                  <div className="d-flex justify-content-between align-items-center w-100 mx-2">
                    <p className="rec_text w-100"> استمع للرسالة</p>
                    <button
                      type="button"
                      class="btn btn-danger"
                      style={{ fontSize: "small" }}
                      onClick={() => {
                        setDelpopup(!delpopup);
                        setRecord(false);
                      }}
                    >
                      الغاء
                    </button>

                    <button
                      onClick={() => {
                        setRecord(false);
                        sendMessage(audioBlob, true, "audio");
                        setAudioBlob(null);
                      }}
                      type="button"
                      class="btn btn-danger mx-2"
                      style={{ fontSize: "small" }}
                    >
                      ارسال
                    </button>
                  </div>
                ) : (
                  <p className="rec_text d-flex justify-content-start align-items-center">
                    أضغط على الميكرفون ليبدأ تسجيل الصوت
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-center align-items-center mt-1 ">
                {audioBlob && (
                  <p className="rec_text"> اضغط زر الارسال ليتم إرسالها </p>
                )}
              </div>
              <div className="d-flex justify-content-center mr-3  ">
                {audioBlob && (
                  <>
                    <AudioPlayer audioBlob={audioBlob} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecorpPopup;
