import { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux'
import { useQuery } from "react-query";
import fetchautomatedfinalquiz from '../../redux-toolkit/api/unit_test/fetchautomatedfinalquiz';
import { useParams } from 'react-router-dom';
import { GetData } from '../../customhook/getDatawithAuth';

export default function ShowallautomatedfinalQuizLogic() {

   const dispatch = useDispatch();
  const {UnitId} = useParams();





// console.log(UnitId);
   const {getDatafrom, isSuccess, progress,IsLoading,IsError,data:final_automated_quiz_by_unit_id} = GetData(`/get_all_final_automated_quiz_by_unit_id?unit_id=${UnitId}`);

    // const {isLoading, isError, data, error,refetch} = useQuery(['finalautomatedquiz',UnitId,page,Auth.token], fetchautomatedfinalquiz,{
    //   enabled: true,
    // });

    useEffect(()=>{
      getDatafrom();
    },[])




      
      return {IsError, final_automated_quiz_by_unit_id,IsLoading,dispatch,getDatafrom}
}


