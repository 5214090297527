import React, { useEffect, useState } from 'react';

function CountDown({ endTime, start, fun }) {
	// console.log(endTime);
	const [time, settime] = useState('00:00:00');
	const [finish, setfinish] = useState(false);
	const [hours, sethours] = useState('0');
	const [minutes, setminutes] = useState('0');
	const [secondes, setsecondes] = useState('0');
	var toHHMMSS = (secs) => {
		var sec_num = parseInt(secs, 10);
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;
		sethours(hours);
		setminutes(minutes);
		setsecondes(seconds);
		// 	settime(`
		// 		${hours >= 10 ? hours : `0 ${hours}`}:${
		// 		minutes >= 10 ? minutes : `0 ${minutes}`
		// 	}:${seconds >= 10 ? seconds : `0 ${seconds}`}`);
	};

	useEffect(() => {
		if (endTime) {
			var a = endTime?.split(':');
			var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

			let interval = null;

			if (start) {
				interval = setInterval(() => {
					if (seconds > 0) {
						seconds--;
						toHHMMSS(seconds);
					} else {
						clearInterval(interval);
						setfinish(true);
					}
				}, 1000);
			} else {
				clearInterval(interval);
				setfinish(true);
			}
			return () => {
				setfinish(true);
				clearInterval(interval);
			};
		}
	}, []);
	return (
		<div>
			الوقت المتبقي
			<h3 className='mb-5'>
				{hours > 9 ? hours : '0' + hours}:
				{minutes > 9 ? minutes : '0' + minutes}:
				{secondes > 9 ? secondes : '0' + secondes}
			</h3>
			{finish && (
				<div class='alert alert-dark' role='alert'>
					يمكنك إرسال الإجابات الآن لكن لن يتم تصحيح الإختبار
				</div>
			)}
		</div>
	);
}

export default CountDown;
