import { lazy } from 'react';
import Loadable from '../../components/Loadable/Loadable';
import PrimaryLayout from '../../Layouts/PrimaryLayout/PrimaryLayout';
import ParticipantsPage from "../pages/Chat/ParticipantsPage/ParticipantsPage";
import Chats from "../../teacher/pages/Chats/Chats";
const Home = Loadable(lazy(() => import('../pages/Home/Home')));
const AboutUs = Loadable(lazy(() => import('../pages/Aboutus/Aboutus')));
const Sections = Loadable(lazy(() => import('../../components/sections/Sections')));
const ScientificBaccalaureate = Loadable(lazy(() => import('../pages/ScientificBaccalaureate/ScientificBaccalaureate')));
const LibrarySite = Loadable(lazy(() => import('../pages/LibrarySite/LibrarySite')));
const WinterCourses = Loadable(lazy(() => import('../pages/WinterCourses/WinterCourses')));
const Login = Loadable(lazy(() => import('../pages/Signin/Signin')));
const Signup = Loadable(lazy(() => import('../pages/Signup/Signup')));
const Subscriptions = Loadable(lazy(() => import('../../components/Subscriptions/Subscriptions')));
const SubscribtionDetails = Loadable(lazy(() => import('../../components/SubscribtionDetails/SubscribtionDetails')));
const TermsandConditionsPage = Loadable(lazy(() => import('../pages/TermsandConditions/TermsandConditions')));
const SectionFiles = Loadable(lazy(() => import('../../components/section_files/index')));
const AllComments = Loadable(lazy(() => import('../pages/allComments/index')));
const NLogin = Loadable(lazy(() => import('../pages/NLogin/index')));
const NSignup = Loadable(lazy(() => import('../pages/NSignup/index')));
const NotActive = Loadable(lazy(() => import('../pages/NotActive/NotActive')));
const CommentsReplies = Loadable(lazy(() => import('../pages/commentReplies/index')));
const Faq = Loadable(lazy(() => import('../pages/Faq/index')));
const EmbedPdf = Loadable(lazy(() => import('../pages/EmbedPdf/index')));

const AudienceRoutes = [
    { path: "/subjects/:subjectId/chat/participants", element: <ParticipantsPage /> },
    { path: "/subjects/:subjectId/chat/participants/:userId", element: <Chats /> },
    {
        path: '/',
        element: <PrimaryLayout />,
        children: [
            {
                path: 'faq',
                element: <Faq />
            },
            {
                path: 'EmbedPdf',
                element: <EmbedPdf />
            },
            {
                path: 'nlogin',
                element: <NLogin />
            },
            {
                path: 'signup',
                element: <NSignup />
            },
            {
                path: 'notactive',
                element: <NotActive />
            },
            {
                index: true,
                element: <Home />
            },
            {
                path: 'sections',
                element: <Sections />
            },
            {
                path: 'aboutus',
                element: <AboutUs />
            },
            {
                path: 'LibrarySite',
                element: <LibrarySite />
            },
            {
                path: 'ScientificBaccalaureate/:id',
                element: <ScientificBaccalaureate />
            },
            {
                path: 'WinterCourses/:id',
                element: <WinterCourses />
            },
            {
                path: 'signin',
                element: <Login />
            },
            // {
            //     path: 'signup',
            //     element: <Signup />
            // },
            {
                path: 'subscribtion',
                children: [
                    {
                        index: true,
                        element: <Subscriptions />

                    },
                    {
                        path: 'subscribtionDetails/:id',
                        element: <SubscribtionDetails />
                    }
                ]
            },
            {
                path: 'TermsandConditions',
                element: <TermsandConditionsPage />
            },
            {
                path: 'LibrarySite/:id',
                element: <SectionFiles />
            },
            {
                path: 'allComments',
                element: <AllComments />
            },
            {
                path: 'comments_replaies/:id',
                element: <CommentsReplies />
            }
        ]
    },


];

export default AudienceRoutes;
