

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const LoginAuth = createAsyncThunk(
    "unit/postCommentUnitwrite",
    async ({
       token,
       opinion,
        }, thunkAPI) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/post_lesson_comment`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "student_id": 1,
                "lesson_id": 1,
                token,
                opinion,
                "updated_at":new Date(),
                "created_at":new Date(),
                "id": 5,
            }),
          }
        )
        let data = await response.json()
        
        if (response.ok) {
           
        //   localStorage.setItem("token", data.token)
          return { ...data, token: token, opinion: opinion }
        } else {
         
          return thunkAPI.rejectWithValue(data)
        }
      } catch (e) {
        
        return thunkAPI.rejectWithValue(e.response.data)
      }
    }
  )


  const initialState={
    post:[],
    loading:false,
    error:null,
}

export const LoginAuthSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {},
  extraReducers:{
    [LoginAuth.pending]: (state,action)=>{
        state.loading =true;
        state.error=null;
    },
    [LoginAuth.fulfilled]: (state,action)=>{
        state.loading = false;
      
        state.post = action.payload;
    },
    [LoginAuth.rejected]:  (state,action)=>{
        
        state.loading=false;
        state.error=action.payload;
    },
  }
})



export default  LoginAuthSlice.reducer




