import React from "react";
import "./index.css";

function Button({
  text,
  className,
  onclick,
  disabled = false,
  backgroundColor,
  style = {},
}) {
  return (
    <div
      className={`subject__subscribeButton ${className && className}`}
      style={style}
    >
      <button
        disabled={disabled}
        onClick={onclick}
        style={{ backgroundColor: backgroundColor }}
      >
        {text}
      </button>
    </div>
  );
}

export default Button;
