import React from "react";

function Popup({ setValidationErrors, validationErrors }) {
  return (
    <div>
      <div className="popupDelete ">
        <div className=" popupDelete_1 col-sm-4 flex-col ">
          <>
            <div className="  mt-3 fs-5 d-flex justify-content-center text-center ">
              {validationErrors}
            </div>
            <div className="col-12 d-flex justify-content-center mt-4 ">
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger w-75"
                  onClick={() => {
                    setValidationErrors(false);
                  }}
                >
                  اغلاق
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Popup;
