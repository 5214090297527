import React, {useState, useEffect} from 'react';
import './index.css';
import rightAnswer from '../../assets/icons/rightcheckbox.png';
import wrongAnswer from '../../assets/icons/wrongcheckbox.png';

import icon from '../../assets/automatedquiz/c5f0fa5ac14327b8330fde1c621ffa8a.jpg';
import uuid from 'react-uuid';

function Questino({
                      question,
                      options,
                      marks,
                      questionId,
                      showAnswersToUser = false,
                      hanldeStudentAnswer,
                      notes,
                      studendAnswers
                  }) {



    const [state, setState] = useState({checked: false});
    const [answers, setAnswers] = useState({});
    const [positions, setPositions] = useState([]);
    const [showNote, setshowNote] = useState();

    const [selectedQuestionOption, setSelectedQuestionOption] = useState();

    const handlechange = (event, option_id) => {
        // // disable activated checkbox
        // if(event.target.checked){
        //     event.target.checked = false;
        // }else {
        //     event.target.checked = true;
        // }

        hanldeStudentAnswer(questionId, option_id);

        let inputs = document.querySelectorAll(`#question_${questionId}`);
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i] != event.target) {
                inputs[i].checked = false;
            }
        }

        setAnswers({question_id: questionId, answer_id: option_id});
        // console.log({ question_id: questionId, answer_id: option_id });
    };

    return (
        <li className={`question`} style={{marginBottom: '25px'}}>
            <div className='question__container'>
                <div className='topsection'>
                    <h6 dangerouslySetInnerHTML={{__html: question}}>{}</h6>
                    <span>{`( ${marks}${marks == 1 ? ' نقطة ' : ' نقاط '} )`}</span>
                </div>

                {(notes == '.' || notes == '..') ? null :
                    <div style={{marginBottom: '10px', paddingBottom: '5px', borderBottom: '1px solid #33333333'}}>

                        {

                            <img loading="lazy"
                                 className={`mt-1 `}
                                 onMouseEnter={() => setshowNote(!showNote)}
                                 onMouseLeave={() => setshowNote(!showNote)}
                                 style={{width: '1.8rem'}}
                                 src={icon}
                            />
                        }

                        {showNote && <sapn>{notes}</sapn>}


                    </div>

                }
                <div className='answers gap-5'>
                    {showAnswersToUser
                        ? options?.map((answer, id) => {
                            console.log(answer)
                            if(answer.answear!='<p><br></p>')
                            return (
                                <div
                                    key={uuid()}
                                    className={`answerItself ${
                                        answer.is_true ? 'rightAnswer' : null
                                    } ${answer.is_true != 1
                                    &&
                                    answers.answer_id == answer.id ? 'wrongAnswer' : null}`}>
                                    <label dangerouslySetInnerHTML={{__html: answer.answear}}
                                           style={{margin: '0px !important'}}>{}</label>

                                    {/* <input
                            type='checkbox' 
                            className={`${ answer.is_true ? 'hidecheckbox' : ''}`}  
                            disabled 
                            onChange={(e) => handlechange(e)} 
                            value={answer.text}
                             name={id} 
                             /> */}

                                    {
                                        <div className='statusImage'>
                                            {answer.is_true == 1 ? (
                                                <img loading="lazy"
                                                     style={{width: '30px', height: '30px'}}
                                                     src={rightAnswer}
                                                     alt=''
                                                />
                                            ) : null}
                                            {answer.is_true != 1 && answers.answer_id == answer.id ? (
                                                <>
                                                    <img loading="lazy"
                                                         style={{width: '20px'}}
                                                         src={wrongAnswer}
                                                         alt=''
                                                    />
                                                    {/* <div>wrong</div> */}
                                                </>
                                            ) : null}
                                        </div>}

                                </div>
                            );
                        })
                        : options?.map((answer, id) => {
                            if(answer.answear!='<p><br></p>')
                                return (
                                <div className={`answerItself`} key={uuid()}>
                                    <input
                                        disabled={
                                            !!(localStorage.getItem('role') == 2 ||
                                                localStorage.getItem('role') == 3
                                                && localStorage.getItem('role'))
                                        }
                                        type='checkbox'
                                        name={`answer`}
                                        id={`question_${questionId}`}
                                        onChange={(e) => handlechange(e, answer.id)}
                                        checked={studendAnswers?.find(ele => ele.aqq_id == questionId)?.option_id == answer.id}
                                    />
                                    <label style={{width: '10rem'}}
                                           dangerouslySetInnerHTML={{__html: answer.answear}}>{}</label>
                                </div>
                            );
                        })}
                </div>
            </div>
        </li>
    );
}

// <img loading="lazy"  src={wrongAnswer} alt=""/>

// className={`${answer.checked &&  ? 'hidecheckbox' : ''}`}
export default Questino;
