import React, { useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";

const ImageWithLoader = ({
  src,
  style,
  onClick,
  size,
  isCircular = false,
  className,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading &&
        (isCircular ? (
          <ShimmerCircularImage
              className={className}

              size={size} />
        ) : (
          <ShimmerThumbnail
              className={className}
              height={size} rounded />
        ))}

      <img
        className={className}
        src={src}
        onClick={onClick}
        alt=""
        onLoad={handleImageLoad}
        style={{
          ...style,
          display: isLoading ? "none" : "block",
        }}
      />
    </>
  );
};

export default ImageWithLoader;
